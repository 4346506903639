// React
import React from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
// Chakra UI
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, useToast } from "@chakra-ui/react";
// Grip API
import useAuth from "../../hooks/useAuth";
import { GetMediaDto } from "../../helpers/GripApi";
// Components
import MediaForm from "../../forms/MediaForm";

const MediaEditPage: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const params: any = useParams();
  const toast = useToast();

  const mediaId = params.id;
  const [media, setMedia] = React.useState<GetMediaDto>();

  React.useEffect(() => {
    if (mediaId) {
      auth.client
        ?.media_Get(mediaId, "1.0")
        .then((response) => {
          setMedia(response.result);
        })
        .catch((error) => {
          toast({
            position: "bottom",
            status: "error",
            title: "There was an error fetching the Media.",
          });
          history.goBack();
        });
    } else {
    }
  }, [auth.client, history, mediaId, toast]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/media-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Media Manager
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Edit Media</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <MediaForm media={media} />
    </>
  );
};

export default MediaEditPage;
