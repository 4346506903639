import { Breadcrumb, BreadcrumbItem, Text, BreadcrumbLink } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import ClientAdminForm from "../../../forms/ClientAdminForm";

const AdminCreatePage: React.FC = () => {
  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Client Admin Manager</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/access-manager/client-admin" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Client Admins
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Add Client Admin</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <ClientAdminForm />
    </>
  );
};

export default AdminCreatePage;
