import { FormLabel, Box, Button, Heading, useToast, SimpleGrid, ButtonGroup, Select, VStack } from "@chakra-ui/react";

import axios from "axios";

import * as React from "react";
import TextField from "../../components/TextField";
import * as yup from "yup";
import { Form, Formik } from "formik";
import {
  CreateGripControllerApplicationModel,
  GetGripControllerApplicationDto,
  UpdateGripControllerApplicationModel,
  GetMediaDto,
} from "../../helpers/GripApi";
import useAuth from "../../hooks/useAuth";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import { useHistory } from "react-router-dom";
import ExitButtonWithConfirmation from "../../components/ExitButtonWithConfirmation";

import { identityServerURL } from "../../helpers/Settings";
import { getUserAccessToken } from "../../helpers/AuthService";
import MediaPreview from "../../components/MediaPreview";

type GripControllerApplicationFormProps = {
  software?: GetGripControllerApplicationDto;
};

const GripControllerApplicationForm: React.FC<GripControllerApplicationFormProps> = ({ software }) => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const [media, setMedia] = React.useState<GetMediaDto[]>();
  const [logo, setLogo] = React.useState<GetMediaDto>();
  const [file, setFile] = React.useState<GetMediaDto>();
  const [file2, setFile2] = React.useState<GetMediaDto>();
  const [file3, setFile3] = React.useState<GetMediaDto>();

  const inital = {
    logo: software?.logo.mediaId || "",
    description: software?.description || "",
    product: software?.product || "",
    version: software?.version || "",
  };

  React.useEffect(() => {
    if (software?.logo) {
      setLogo(software?.logo);
    }
    if (software?.file1) {
      setFile(software?.file1);
    }
    if (software?.file2) {
      setFile2(software?.file2);
    }
    if (software?.file3) {
      setFile3(software?.file3);
    }

    auth.client
      ?.media_GetAll("1.0")
      .then((response) => {
        setMedia(response.result);

        if (response.result.length > 0) {
          if (!software?.logo) {
            setLogo(response.result[0]);
          }
          if (!software?.file1) {
            setFile(response.result[0]);
          }
        }
      })
      .catch((error) => {});
  }, [auth.client]);

  const deleteHandle = () => {
    if (software?.id) {
      auth.client
        ?.gripControllerApplication_Delete(software?.id, "1.0")
        .then(() => {
          toast({
            position: "bottom",
            status: "success",
            title: "Grip Controller Application deleted",
          });
          history.goBack();
        })
        .catch((e) => handleError(e.errors?.Error[0]));
    }
  };

  const handleResponse = (title: string) => {
    toast({
      position: "bottom",
      status: "success",
      title: title,
    });
  };

  const handleError = (e: Error, title?: string) => {
    if (title) {
      toast({
        position: "bottom",
        status: "error",
        title: title,
      });
    }
  };

  const logoSelected = (index: string) => {
    let logo = media?.filter((e) => e.mediaId === index);
    if (logo && logo.length > 0) {
      setLogo(logo[0]);
    }
  };

  const fileSelected = (index: string) => {
    let logo = media?.filter((e) => e.mediaId === index);
    if (logo && logo.length > 0) {
      setFile(logo[0]);
    }
  };

  const file2Selected = (index: string) => {
    let logo = media?.filter((e) => e.mediaId === index);
    if (logo && logo.length > 0) {
      setFile2(logo[0]);
    } else {
      setFile2(undefined);
    }
  };

  const file3Selected = (index: string) => {
    let logo = media?.filter((e) => e.mediaId === index);
    if (logo && logo.length > 0) {
      setFile3(logo[0]);
    } else {
      setFile3(undefined);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={inital}
      onSubmit={(values) => {
        let firstMedia;
        if (media && media.length > 0) {
          firstMedia = media[0];
        }
        const newClient: CreateGripControllerApplicationModel = {
          logoId: logo?.mediaId ?? firstMedia?.mediaId ?? "",
          description: values.description,
          product: values.product,
          version: values.version,
          file1Id: file?.mediaId ?? firstMedia?.mediaId ?? "",
          file2Id: file2?.mediaId ?? null,
          file3Id: file3?.mediaId ?? null,
        };

        if (software?.id) {
          const existingClient = newClient as UpdateGripControllerApplicationModel;
          existingClient.id = software?.id;

          auth.client
            ?.gripControllerApplication_Update(existingClient.id, "1.0", existingClient)
            .then(() => {
              handleResponse("Grip Controller Application was successfully updated.");
              history.goBack();
            })
            .catch((e) => handleError(e.errors?.Error[0]));
        } else {
          auth.client
            ?.gripControllerApplication_Create("1.0", newClient)
            .then((r) => {
              handleResponse("Grip Controller Application was successfully created.");
              history.goBack();
            })
            .catch((e) => handleError(e.errors));
        }
      }}
    >
      {({ values: v, dirty, setFieldValue }) => (
        <Box p="6" rounded="md" backgroundColor="background">
          <Form noValidate>
            <Heading size="lg" mt={2} mb={6}>
              {software?.id ? "Editing " : "Adding "} GRIP Application Software
            </Heading>
            <SimpleGrid spacing={10} minChildWidth="500px">
              <Box>
                <SimpleGrid spacing={5} minChildWidth="350px">
                  <VStack alignItems="left">
                    <FormLabel>Image</FormLabel>
                    {logo && <MediaPreview maxWidth={100} src={logo.filePath} filename={logo.fileNameOriginal} />}
                    <Select value={logo?.mediaId} onChange={(e) => logoSelected(e.target.value)} isRequired>
                      {media &&
                        media.map(function (media, index) {
                          return (
                            <option key={media?.mediaId} value={media?.mediaId}>
                              {media?.fileNameOriginal}
                            </option>
                          );
                        })}
                    </Select>
                    <TextField title="Description" name="description" />
                    <TextField title="Product" name="product" />
                    <TextField title="Revision" name="version" />
                    <FormLabel>File 1</FormLabel>
                    {file && <MediaPreview maxWidth={100} src={file.filePath} filename={file.fileNameOriginal} />}

                    <Select value={file?.mediaId} onChange={(e) => fileSelected(e.target.value)} isRequired>
                      {media &&
                        media.map(function (media, index) {
                          return (
                            <option key={media?.mediaId} value={media?.mediaId}>
                              {media?.fileNameOriginal}
                            </option>
                          );
                        })}
                    </Select>

                    <FormLabel>File 2</FormLabel>
                    {file2 && <MediaPreview maxWidth={100} src={file2.filePath} filename={file2.fileNameOriginal} />}

                    <Select value={file2?.mediaId} onChange={(e) => file2Selected(e.target.value)} isRequired>
                      <option key="null" value="null">
                        Please select a file
                      </option>
                      {media &&
                        media.map(function (media, index) {
                          return (
                            <option key={media?.mediaId} value={media?.mediaId}>
                              {media?.fileNameOriginal}
                            </option>
                          );
                        })}
                    </Select>

                    <FormLabel>File 3</FormLabel>
                    {file3 && <MediaPreview maxWidth={100} src={file3.filePath} filename={file3.fileNameOriginal} />}

                    <Select value={file3?.mediaId} onChange={(e) => file3Selected(e.target.value)} isRequired>
                      <option key="null" value="null">
                        Please select a file
                      </option>
                      {media &&
                        media.map(function (media, index) {
                          return (
                            <option key={media?.mediaId} value={media?.mediaId}>
                              {media?.fileNameOriginal}
                            </option>
                          );
                        })}
                    </Select>
                  </VStack>
                </SimpleGrid>
              </Box>
            </SimpleGrid>
            <ButtonGroup pt={10} spacing={5}>
              <Button type="submit" variant="solid" colorScheme="gripgreen" bg="gripgreen">
                Save
              </Button>

              <ExitButtonWithConfirmation onConfirm={history.goBack} isDirty={dirty} />
              {software?.id && <DeleteConfirmationDialog handleDelete={deleteHandle} />}
            </ButtonGroup>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default GripControllerApplicationForm;
