import React from "react";
import { useParams } from "react-router";
import useAuth from "../../hooks/useAuth";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import AdminForm from "../../forms/AdminForm";
import { GetUserDto } from "../../helpers/GripApi";

const AdminEditPage: React.FC = () => {
  const params: any = useParams();
  const auth = useAuth();
  const [admin, setAdmin] = React.useState<GetUserDto>();

  const userId = params.userId;

  React.useEffect(() => {
    if (userId) {
      auth.client?.admin_Get(userId, "1.0").then((r) => {
        setAdmin(r.result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Admin Manager</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/access-manager/grip-admin" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Admins
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Edit Admin</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <AdminForm admin={admin} profile={false} />
    </>
  );
};

export default AdminEditPage;
