import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

const theme = extendTheme({
  styles: {
    SimpleGrid: {
      color: "teal.500",
    },
  },
  fonts: {
    heading: "Open Sans",
    body: "Open Sans",
  },
  colors: {
    background: "#F0F0F0",
    gripgreen: "#708c24",
  },
  breakpoints,
});

export default theme;
