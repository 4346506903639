import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { FastField, Field, FieldProps } from "formik";
import * as React from "react";

type TextFieldProps = {
  title: string;
  isRequired?: boolean;
  type?: "text" | string;
  name: string;
  maxLength?: 524288 | number;
  error?: string | undefined;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  hasReliance?: boolean;
  isReadOnly?: boolean;
  reference?: string;
  onChanged?: any;
};

const TextField: React.FC<TextFieldProps> = ({
  type,
  name,
  title,
  maxLength,
  onChanged,
  disabled = false,
  isRequired = false,
  hasReliance = false,
  isReadOnly = false,
  reference = "",
}) => {
  // const inputRef = React.useRef(reference);
  const content = ({ field, meta }: FieldProps) => (
    <FormControl id={name} isRequired={isRequired} isInvalid={(meta.error && meta.touched) || false}>
      <FormLabel>{title}</FormLabel>
      <Input
        type={type}
        {...field}
        maxLength={maxLength}
        name={name}
        onKeyUp={onChanged}
        disabled={disabled}
        placeholder={title}
        isReadOnly={isReadOnly}
      />
      {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  );

  return hasReliance ? (
    <Field name={name} id={reference}>
      {content}
    </Field>
  ) : (
    <FastField name={name}>{content}</FastField>
  );
};

export default TextField;
