import * as React from "react";
import { NavLink } from "react-router-dom";
import { HStack, Box, Text, Stack, Collapse } from "@chakra-ui/react";
import { useState } from "react";

import DownIcon from "mdi-react/ChevronDownIcon";
import UpIcon from "mdi-react/ChevronUpIcon";

export type SidebarItemChildProps = {
  title: string;
  path: string;
};

type SidebarItemProps = {
  title: string;
  icon: React.ReactElement;
  path?: string;
  children?: SidebarItemChildProps[];
};

const SidebarItem: React.FC<SidebarItemProps> = ({ title, icon, path, children }) => {
  var shouldExpand = true;
  // if (children) {
  //   shouldExpand = !children.every(
  //     (c) => window.location.href.indexOf(c.path) === -1
  //   );
  // }

  const [isExpanded, setIsExpanded] = useState(shouldExpand);

  const content = (
    <>
      <HStack onClick={() => setIsExpanded(!isExpanded)} cursor="pointer">
        <Box m={2} p={2}>
          {icon}
        </Box>
        <Box ml="3" alignContent="center">
          <Text color="gripgreen" casing="uppercase" fontSize="13" style={{ fontFamily: "Roboto, sans-serif" }}>
            {title}
          </Text>
        </Box>
        {children && <div style={{ marginLeft: "auto" }}>{isExpanded ? <UpIcon color="gripgreen" /> : <DownIcon color="gripgreen" />}</div>}
      </HStack>
      {children && (
        <Collapse in={isExpanded} animateOpacity>
          <Stack>
            {children.map((v, i) => (
              <NavLink key={`${i}_${v.path}_key`} className="subItem" to={v.path} activeClassName="activeNavLink">
                <Text color="gripgreen" fontSize="13" style={{ fontFamily: "Roboto, sans-serif" }}>
                  {v.title}
                </Text>
              </NavLink>
            ))}
          </Stack>
        </Collapse>
      )}
    </>
  );

  return path && path?.length > 0 ? (
    <Stack px={3}>
      <NavLink to={path} exact activeClassName="activeNavLink">
        {content}
      </NavLink>
    </Stack>
  ) : (
    <Stack px={3}>{content}</Stack>
  );
};

export default SidebarItem;
