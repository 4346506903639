import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import KitForm from "../../forms/KitForm";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { GetKitDto } from "../../helpers/GripApi";

const KitEditPage: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const toast = useToast();

  const params: any = useParams();
  const kitId = params.kitId;

  const [kit, setKit] = React.useState<GetKitDto>();

  React.useEffect(() => {
    if (kitId) {
      auth.client
        ?.kit_Get(kitId, "1.0")
        .then((response) => {
          setKit(response.result);
        })
        .catch((error) => {
          toast({
            position: "bottom",
            status: "error",
            title: "There was an error fetching this Kit.",
          });
          history.goBack();
        });
    } else {
      toast({
        position: "bottom",
        status: "error",
        title: "There was an error fetching this Kit.",
      });
    }
  }, [auth.client, history, kitId, toast]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/kit-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Kit Manager
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>View Kit</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <KitForm kit={kit} />
    </>
  );
};

export default KitEditPage;
