import * as React from "react";
import { Box, Center, IconButton, Menu, MenuButton, MenuList, MenuItem, Text, Flex, Image } from "@chakra-ui/react";
import "../App.css";

import MenuIcon from "mdi-react/MenuIcon";
import CaretDownFillIcon from "mdi-react/ChevronDownIcon";
import PersonIcon from "mdi-react/AccountIcon";

import { mgr, getUsername } from "../helpers/AuthService";

type HeaderProps = {
  onShowSidebar: Function;
  showSidebarButton?: boolean;
  title: string;
};

export const Header: React.FC<HeaderProps> = (props) => {
  function logout() {
    window.localStorage.setItem("user", "");
    mgr.signoutRedirect();
  }

  return (
    <Flex
      className="header"
      p={2}
      bg="gripgreen"
      height="64px"
      borderBottom="1px"
      borderColor="#e9eaee"
      justifyContent="center"
      position="sticky"
      top="0px"
      zIndex="5"
    >
      <Box>
        {props.showSidebarButton && (
          <>
            <IconButton icon={<MenuIcon />} colorScheme="blackAlpha" variant="ghost" onClick={() => props.onShowSidebar()} aria-label="Menu" />
          </>
        )}
      </Box>
      <Image src="/logo.png" alt="EPEQ IM" />
      <Center flex="1"></Center>
      <Center>
        <Box>
          <Menu>
            <MenuButton transition="all 0.2s">
              <Flex>
                <Center>
                  <Box>
                    <Box borderRadius="xl">
                      <PersonIcon size={24} color="white" />
                    </Box>
                  </Box>
                  <Box ml="2">
                    <Text fontWeight="500" color="white">
                      {getUsername()}
                    </Text>
                  </Box>
                  <Box px={2}>
                    <CaretDownFillIcon color="white" size={24} />
                  </Box>
                </Center>
              </Flex>
            </MenuButton>
            <MenuList color="black">
              {/* <NavLink to="/logout"> */}
              <MenuItem onClick={() => logout()} minH="48px">
                Logout
              </MenuItem>
              {/* </NavLink> */}
            </MenuList>
          </Menu>
        </Box>
      </Center>
    </Flex>
  );
};

export default Header;
