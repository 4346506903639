import { Box, Button, Heading, useToast, SimpleGrid, ButtonGroup, Select, FormControl, FormLabel } from "@chakra-ui/react";

import axios from "axios";

import * as React from "react";
import TextField from "../components/TextField";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { CreateUser, GetUserDto, UpdateUser, GetClientDto } from "../helpers/GripApi";
import useAuth from "../hooks/useAuth";
import DeleteConfirmationDialog from "../components/DeleteConfirmationDialog";
import { useHistory } from "react-router-dom";
import ExitButtonWithConfirmation from "../components/ExitButtonWithConfirmation";
import SelectField from "../components/SelectField";

import { identityServerURL } from "../helpers/Settings";
import { getUserAccessToken } from "../helpers/AuthService";

type ClientAdminFormProps = {
  admin?: GetUserDto;
  profile?: boolean;
};

const emailErr = "Invalid Email Address (Ex. name@domain.ca)";

const formatReq = (title: string) => `${title} is a required field.`;
const formatLen = (title: string, length: number) => `${title} should not exceed ${length} characters in length.`;

const validationSchema = yup.object().shape({
  firstName: yup.string().max(50, formatLen("First Name", 50)).required(formatReq("First Name")),
  lastName: yup.string().max(50, formatLen("Last Name", 50)).required(formatReq("Last Name")),
  emailAddress: yup.string().email(emailErr).max(256, formatLen("Email Address", 256)).required(formatReq("Email Address")),
  client: yup.string().required(formatReq("Client")),
});

const ClientAdminForm: React.FC<ClientAdminFormProps> = ({ admin, profile }) => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const [clients, setClients] = React.useState<GetClientDto[]>();
  const [client, clientIdSelected] = React.useState<string>();

  React.useEffect(() => {
    auth.client?.client_GetAll(undefined, "1.0").then((r) => {
      setClients(r.result);
    });
  }, [auth.client]);

  const inital = {
    firstName: admin?.firstName || "",
    lastName: admin?.lastName || "",
    emailAddress: admin?.email || "",
    isDeleted: admin?.isDeleted || "",
    client: (admin?.clients ?? []).length > 0 ? admin?.clients[0].clientId : "",
  };

  const deleteHandle = () => {
    if (admin?.userId) {
      // Update identity server

      var identityConfig = {
        headers: {
          "Content-Type": "application/json",
          "api-version": "1.0",
          Authorization: `Bearer ${getUserAccessToken()}`,
        },
      };

      axios.delete(`${identityServerURL}/api/user/${admin.userId}`, identityConfig).then(() => {
        auth.client
          ?.admin_Delete(admin?.userId, "1.0")
          .then(() => {
            toast({
              position: "bottom",
              status: "success",
              title: "Admin deleted",
            });
            history.goBack();
          })
          .catch((e) => handleError(e.errors?.Error[0]));
      });
    }
  };

  const handleResponse = (title: string) => {
    toast({
      position: "bottom",
      status: "success",
      title: title,
    });
  };

  const handleError = (e: Error, title?: string) => {
    if (title) {
      toast({
        position: "bottom",
        status: "error",
        title: title,
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={inital}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const newClient: CreateUser = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.emailAddress,
          userRole: 2,
          userId: "TODO",
          clientIds: [values.client ?? ""],
        };

        var identityConfig = {
          headers: {
            "Content-Type": "application/json",
            "api-version": "1.0",
            Authorization: `Bearer ${getUserAccessToken()}`,
          },
        };

        if (admin?.userId) {
          const existingClient = newClient as UpdateUser;
          existingClient.userId = admin?.userId;

          // Update identity server
          axios
            .put(
              `${identityServerURL}/api/user/${existingClient.userId}`,
              {
                id: existingClient.userId,
                email: newClient.email,
                firstName: newClient.firstName,
                lastName: newClient.lastName,
                role: "gripidle.gripportaluser",
                isEnabled: true,
              },
              identityConfig
            )
            .then(() => {
              auth.client
                ?.admin_Update(existingClient.userId, "1.0", existingClient)
                .then(() => {
                  handleResponse("Admin was successfully updated.");
                  history.goBack();
                })
                .catch((e) => handleError(e.errors?.Error[0]));
            });
        } else {
          axios
            .post(
              `${identityServerURL}/api/user`,
              {
                email: newClient.email,
                firstName: newClient.firstName,
                lastName: newClient.lastName,
                role: "gripidle.gripportaluser",
              },
              identityConfig
            )
            .then((e) => {
              newClient.userId = e.data.id;
              auth.client
                ?.admin_Create("1.0", newClient)
                .then((r) => {
                  handleResponse("Client was successfully created.");
                  history.goBack();
                })
                .catch((e) => {
                  handleError(e.errors);
                });
            })
            .catch((e) => {
              if (e.response && e.response.status === 400) {
                handleError(e, "Email already in use");
              } else {
                handleError(e, "Error creating admin");
              }
            });
        }
      }}
    >
      {({ values: v, dirty, setFieldValue }) => (
        <Box p="6" rounded="md" backgroundColor="background">
          <Form noValidate>
            <Heading size="lg" mt={2} mb={6}>
              {admin?.userId ? "Editing " : "Adding "} a Client Admin
            </Heading>
            <SimpleGrid spacing={10} minChildWidth="500px">
              <Box>
                <Heading as="h4" size="sm" mb="2">
                  User Info
                </Heading>
                <SimpleGrid spacing={5} minChildWidth="350px">
                  <TextField title="First name" name="firstName" isRequired />
                  <TextField title="Last name" name="lastName" isRequired />
                  <TextField title="Email address" name="emailAddress" isRequired isReadOnly={admin?.userId ? true : false} />
                  <SelectField placeholder="Client" name="client" clients={clients} />
                </SimpleGrid>
              </Box>
            </SimpleGrid>
            <ButtonGroup pt={10} spacing={5}>
              <Button type="submit" variant="solid" colorScheme="gripgreen" bg="gripgreen">
                {inital.isDeleted.toLowerCase() !== "yes" && <>Save</>}
                {inital.isDeleted.toLowerCase() === "yes" && <>Reactivate</>}
              </Button>
              {!profile && inital.isDeleted.toLowerCase() !== "yes" && (
                <>
                  <ExitButtonWithConfirmation onConfirm={history.goBack} isDirty={dirty} />
                  {admin?.userId && <DeleteConfirmationDialog title="Deactivate" isSoftDelete={true} handleDelete={deleteHandle} />}
                </>
              )}
              {!profile && inital.isDeleted.toLowerCase() === "yes" && (
                <>
                  <Button onClick={history.goBack} variant="solid" colorScheme="gripgreen" bg="gripgreen">
                    Cancel
                  </Button>
                </>
              )}
            </ButtonGroup>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default ClientAdminForm;
