import { Box, Button, Container, Heading, useToast, ButtonGroup } from "@chakra-ui/react";
import * as React from "react";
import TextArea from "../components/TextArea";
import TextField from "../components/TextField";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { CreatePartCategoryModel, GetPartCategoryDto, UpdatePartCategoryModel } from "../helpers/GripApi";
import { isAllowedToEdit } from "../helpers/AuthService";
import useAuth from "../hooks/useAuth";
import DeleteConfirmationDialog from "../components/DeleteConfirmationDialog";
import { useHistory } from "react-router-dom";
import ExitButtonWithConfirmation from "../components/ExitButtonWithConfirmation";

type PartCategoryFormProps = {
  partCategory?: GetPartCategoryDto;
};

const validationSchema = yup.object().shape({
  name: yup.string().max(50, "Max length 50 characers").required("Name is required"),
  description: yup.string().max(500, "Max length 500 characers").required("Description is required"),
});

const PartCategoryForm: React.FC<PartCategoryFormProps> = ({ partCategory }) => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const initialValues: CreatePartCategoryModel = {
    name: partCategory?.name || "",
    description: partCategory?.description || "",
  };

  const deleteHandle = () => {
    if (partCategory?.partCategoryId) {
      auth.client
        ?.partCategory_Delete(partCategory?.partCategoryId, "1.0")
        .then(() => {
          toast({
            position: "bottom",
            status: "success",
            title: "Product Code Deleted",
          });
          history.goBack();
        })
        .catch((e) => handleError(e.errors?.Error[0]));
    }
  };

  const handleError = (title?: string) => {
    if (title) {
      toast({
        position: "bottom",
        status: "error",
        title: title,
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        if (partCategory?.partCategoryId) {
          const existingCategory = values as UpdatePartCategoryModel;
          existingCategory.partCategoryId = partCategory?.partCategoryId;
          auth.client
            ?.partCategory_Update(existingCategory.partCategoryId, "1.0", existingCategory)
            .then(() => {
              toast({
                position: "bottom",
                status: "success",
                title: "Category updated",
              });
              history.goBack();
            })
            .catch((e) => handleError(e.errors?.Error[0]));
        } else {
          auth.client
            ?.partCategory_Create("1.0", values)
            .then(() => {
              toast({
                position: "bottom",
                status: "success",
                title: "Category added",
              });
              history.goBack();
            })
            .catch((e) => handleError(e.errors?.Error[0]));
        }
      }}
    >
      {({ values, dirty }) => (
        <Container maxW="container.md" marginStart="start">
          <Box p="6" rounded="lg" backgroundColor="background">
            <Form noValidate>
              <Heading size="lg" mt={2} mb={6}>
                {partCategory?.partCategoryId ? "Edit" : "Add"} Product Code
              </Heading>
              <Box pb={5}>
                <TextField title="Name" name="name" maxLength={50} isRequired />
              </Box>
              <Box pb={5}>
                <TextArea name="description" maxLength={500} placeholder="Description" />
              </Box>
              <ButtonGroup pt={5} spacing={5}>
                <Button type="submit" colorScheme="gripgreen" bg="gripgreen">
                  Save
                </Button>
                <ExitButtonWithConfirmation onConfirm={history.goBack} isDirty={dirty} />
                {partCategory?.partCategoryId && <DeleteConfirmationDialog handleDelete={deleteHandle} />}
              </ButtonGroup>
            </Form>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default PartCategoryForm;
