import { Breadcrumb, BreadcrumbItem, Text, BreadcrumbLink } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import ClientForm from "../../forms/ClientForm";

const ClientCreatePage: React.FC = () => {
  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Client Manager</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/client-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Clients
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Add Client</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <ClientForm />
    </>
  );
};

export default ClientCreatePage;
