/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, RouteProps } from "react-router";
import DashboardLayout from "../layout/DashboardLayout";

const AuthenticatedRoute: React.FC<RouteProps> = (props) => {
  return (
    <DashboardLayout>
      <Route {...props} />
    </DashboardLayout>
  );
};

export default AuthenticatedRoute;
