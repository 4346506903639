import React, { FunctionComponent, useState } from "react";
import { Heading, Box } from "@chakra-ui/layout";
import { Button, Breadcrumb, BreadcrumbItem, Text, Flex, Spacer, Radio, RadioGroup, Stack } from "@chakra-ui/react";

import { useHistory } from "react-router";
import { GetClientDto } from "../../helpers/GripApi";

import useAuth from "../../hooks/useAuth";
import Table from "../../components/Table";

const ClientManagerPage: FunctionComponent = () => {
  const auth = useAuth();
  const history = useHistory();

  const [clients, setClients] = useState<GetClientDto[]>([]);
  const [value, setValue] = useState<string>("2");

  const columns = React.useMemo(
    () => [
      {
        Header: "Username",
        accessor: "userName",
      },
      {
        Header: "Email Address",
        accessor: "emailAddress",
      },
      {
        Header: "Full Name",
        id: "fullName",
        accessor: (data: GetClientDto) => (
          <span>
            {data.firstName} {data.lastName}
          </span>
        ),
      },
      {
        Header: "Business Phone Number",
        accessor: "businessPhoneNumber",
      },
      {
        Header: "Cell Phone",
        accessor: "cellPhoneNumber",
      },
      // {
      //   Header: "User Type",
      // },
      // {
      //   Header: "Access Level",
      // },
      // {
      //   Header: "Product Type",
      // },
      {
        Header: "Distributor",
        accessor: "representativeDistributor",
      },
      {
        Header: "Company Name",
        accessor: "company",
      },
      {
        Header: "Address",
        accessor: "billingAddress",
      },
      {
        Header: "City",
        accessor: "billingCity",
      },
      {
        Header: "State/Province",
        accessor: "billingProvState",
      },
      {
        Header: "Country",
        accessor: "billingCountry",
      },
      {
        Header: "Postal Code",
        accessor: "billingPostalCode",
      },
    ],
    []
  );

  const onOpen = () => {
    history.push("/client-manager/create");
  };

  React.useEffect(() => {
    auth.client?.client_GetAll(undefined, "1.0").then((response) => {
      setClients(response.result);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (row: GetClientDto) => {
    history.push({
      pathname: "/client-manager/" + row.clientId,
      state: row,
    });
  };

  return (
    <>
      <Flex w="100%">
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
          <BreadcrumbItem>
            <Text>Client Manager</Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>Clients</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
        <Button colorScheme="gripgreen" bg="gripgreen" onClick={onOpen}>
          Add Client
        </Button>
      </Flex>

      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6}>
          Clients
        </Heading>
        {/* <Box p={4} borderRadius="lg" mb={5}>
          <RadioGroup onChange={setValue} value={value}>
            <Stack direction="row">
              <Radio value="1">All Clients</Radio>
              <Radio value="2">Activate Clients</Radio>
              <Radio value="3">Deactivated clients</Radio>
            </Stack>
          </RadioGroup>
        </Box> */}
        <Table
          onRowClicked={handleRowClick}
          data={clients.filter((e) => value === "1" || (value === "2" && e.isDeleted === false) || (value === "3" && e.isDeleted === true))}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default ClientManagerPage;
