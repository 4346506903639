import { FunctionComponent } from "react";

import { gripPortalURL } from "../../helpers/Settings";

const VehicleManagerPage: FunctionComponent = () => {
  window.location.href = `${gripPortalURL}/vehicleprofiles`;

  return <></>;
};

export default VehicleManagerPage;
