import axios from "axios";
import React from "react";
import { Client } from "../helpers/GripApi";
import { setupInterceptorsTo } from "../helpers/Interceptors";
import AuthReducer, { AuthState } from "../reducers/AuthReducer";
import AuthContext from "./AuthContext";

import { apiURL } from "../helpers/Settings";

type EmployeeActionProps = {
  children: React.ReactNode;
};

const AuthContextProvider = ({ children }: EmployeeActionProps) => {
  const createClient = (token: string | null) => {
    const axiosInstance = axios.create({
      transformResponse: [],
    });

    if (token) {
      setupInterceptorsTo(axiosInstance);
    }

    return new Client(apiURL, axiosInstance);
  };

  const client = createClient(null);

  const initialState: AuthState = {
    client: client,
  };

  const [authState] = React.useReducer(AuthReducer, initialState);

  return (
    <AuthContext.Provider
      value={{
        client: authState.client,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
