import {
  Box,
  Button,
  Heading,
  useToast,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  ButtonGroup,
  HStack,
} from "@chakra-ui/react";

import axios from "axios";

import * as React from "react";
import TextField from "../components/TextField";
import * as yup from "yup";
import { Field, Form, Formik, setIn } from "formik";
import { CreateClientModel, CreateUser, GetClientDto, UpdateClientModel } from "../helpers/GripApi";
import useAuth from "../hooks/useAuth";
import DeleteConfirmationDialog from "../components/DeleteConfirmationDialog";
import { useHistory } from "react-router-dom";
import ExitButtonWithConfirmation from "../components/ExitButtonWithConfirmation";
import { isSuperAdmin } from "../helpers/AuthService";

import { identityServerURL } from "../helpers/Settings";
import { getUserAccessToken } from "../helpers/AuthService";

type ClientFormProps = {
  client?: GetClientDto;
  profile?: boolean;
};

const phoneValidation = (value: string | undefined) =>
  /(?=(?:^(?:\+?1\s*(?:[.-]\s*)?)?(?!(?:(?:.*\(.*)|(?:.*\).*)))(?:[2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))|(?:.*\((?:[2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\).*))(?:\+?1\s*(?:[.-]\s*)?)?(?:\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\)?)\s*(?:[.-]\s*)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d{1,15}))?$/.test(
    String(value!)
  ) || value === undefined;

const urlValidation = (value: string | undefined) =>
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
    String(value!)
  ) || value === undefined;

const emailErr = "Invalid Email Address (Ex. name@domain.ca)";
const phoneErr = "Invalid Phone Number (Ex. 905-555-1234)";
const urlErr = "Invalid URL (Ex. https://gripidlemanagement.com)";
const formatReq = (title: string) => `${title} is a required field.`;
const formatLen = (title: string, length: number) => `${title} should not exceed ${length} characters in length.`;

const validationSchema = yup.object().shape({
  firstName: yup.string().max(50, formatLen("First Name", 50)).required(formatReq("First Name")),
  lastName: yup.string().max(50, formatLen("Last Name", 50)).required(formatReq("Last Name")),
  company: yup.string().max(50, formatLen("Company", 50)).required(formatReq("Company")),

  title: yup.string().max(100, formatLen("Title", 100)),
  department: yup.string().max(100, formatLen("Department", 100)),

  emailAddress: yup.string().email(emailErr).max(256, formatLen("Email Address", 256)).required(formatReq("Email Address")),
  businessPhoneNumber: yup.string().max(50, formatLen("Business Phone Number", 50)).test("phoneNumber", phoneErr, phoneValidation),
  businessPhoneNumberExtension: yup.string().max(50, formatLen("Extension", 50)),
  cellPhoneNumber: yup.string().max(50, formatLen("Cell Phone Number", 50)).test("cellPhoneNumber", phoneErr, phoneValidation),
  faxNumber: yup.string().max(50, formatLen("Fax Number", 50)).test("faxNumber", phoneErr, phoneValidation),
  otherPhoneNumber: yup.string().max(50, formatLen("Other Number", 50)).test("otherPhoneNumber", phoneErr, phoneValidation),
  companyWebsiteUrl: yup.string().max(256, formatLen("Website URL", 256)).test("companyWebsiteUrl", urlErr, urlValidation),

  billingAddress: yup.string().max(200, formatLen("Address", 200)),
  billingAddress2: yup.string().max(200, formatLen("Address 2", 200)),
  billingCity: yup.string().max(50, formatLen("City", 50)),
  billingProvState: yup.string().max(2, formatLen("Province/State", 2)),
  billingCountry: yup.string().max(50, formatLen("Country", 50)),
  billingPostalCode: yup.string().max(10, formatLen("Postal/Zip Code", 10)),

  shippingAddress: yup.string().max(200, formatLen("Address", 200)),
  shippingAddress2: yup.string().max(200, formatLen("Address 2", 200)),
  shippingCity: yup.string().max(50, formatLen("City", 50)),
  shippingProvState: yup.string().max(2, formatLen("Province/State", 2)),
  shippingCountry: yup.string().max(50, formatLen("Country", 50)),
  shippingPostalCode: yup.string().max(10, formatLen("Postal/Zip Code", 10)),

  backupContactName: yup.string().max(50, formatLen("Contact Name", 50)),
  backupContactTitle: yup.string().max(100, formatLen("Title", 100)),
  backupContactDepartment: yup.string().max(100, formatLen("Department", 100)),
  backupContactEmailAddress: yup.string().email(emailErr).max(256, formatLen("Email Address", 256)),
  backupContactBusinessPhoneNumber: yup.string().max(50, formatLen("Business Phone Number", 50)).test("phoneNumber", phoneErr, phoneValidation),
  backupContactBusinessPhoneNumberExtension: yup.string().max(50, formatLen("Extension", 50)),
  backupContactCell: yup.string().max(50, formatLen("Cell Phone Number", 50)).test("cell", phoneErr, phoneValidation),
  backupContactFax: yup.string().max(50, formatLen("Fax Number", 50)).test("fax", phoneErr, phoneValidation),
  backupContactOtherPhoneNumber: yup.string().max(50, formatLen("Other Number", 50)).test("otherPhoneNumber", phoneErr, phoneValidation),

  representativeGrip: yup.string().max(50, formatLen("GRIP Contact Person", 50)),
  representativeDistributor: yup.string().max(50, formatLen("Purchasing Distributor", 50)),
  representativeDealer: yup.string().max(50, formatLen("Preferred Installer", 50)),
});

const ClientForm: React.FC<ClientFormProps> = ({ client, profile }) => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const [isDifferentEmail, setIsDifferentEmail] = React.useState<Boolean>();

  const [invited, setInvited] = React.useState<boolean | undefined>(undefined);
  if (client?.invited && invited == undefined) {
    setInvited(true);
  }

  const inital = {
    id: client?.clientId,
    firstName: client?.firstName || "",
    lastName: client?.lastName || "",

    title: client?.title || "",
    department: client?.department || "",
    company: client?.company || "",
    isDeleted: client?.isDeleted || false,

    emailAddress: client?.emailAddress || "",
    businessPhoneNumber: client?.businessPhoneNumber || "",
    businessPhoneNumberExtension: client?.businessPhoneNumberExtension || "",
    cellPhoneNumber: client?.cellPhoneNumber || "",
    faxNumber: client?.faxNumber || "",
    otherPhoneNumber: client?.otherPhoneNumber || "",
    companyWebsiteUrl: client?.companyWebsiteUrl || "",

    billingAddress: client?.billingAddress || "",
    billingAddress2: client?.billingAddress2 || "",
    billingCity: client?.billingCity || "",
    billingProvState: client?.billingProvState || "",
    billingCountry: client?.billingCountry || "",
    billingPostalCode: client?.billingPostalCode || "",

    shippingAddress: client?.shippingAddress || "",
    shippingAddress2: client?.shippingAddress2 || "",
    shippingCity: client?.shippingCity || "",
    shippingProvState: client?.shippingProvState || "",
    shippingCountry: client?.shippingCountry || "",
    shippingPostalCode: client?.shippingPostalCode || "",

    backupContactName: client?.backupContactName || "",
    backupContactTitle: client?.backupContactTitle || "",
    backupContactDepartment: client?.backupContactDepartment || "",
    backupContactEmailAddress: client?.backupContactEmailAddress || "",
    backupContactBusinessPhoneNumber: client?.backupContactBusinessPhoneNumber || "",
    backupContactBusinessPhoneNumberExtension: client?.backupContactBusinessPhoneNumberExtension || "",
    backupContactCell: client?.backupContactCell || "",
    backupContactFax: client?.backupContactFax || "",
    backupContactOtherPhoneNumber: client?.backupContactOtherPhoneNumber || "",

    representativeGrip: client?.representativeGrip || "",
    representativeDistributor: client?.representativeDistributor || "",
    representativeDealer: client?.representativeDealer || "",

    isSameAsBilling:
      (client &&
        client["shippingAddress"] === client["billingAddress"] &&
        client["shippingAddress2"] === client["billingAddress2"] &&
        client["shippingCity"] === client["billingCity"] &&
        client["shippingProvState"] === client["billingProvState"] &&
        client["shippingCountry"] === client["billingCountry"] &&
        client["shippingPostalCode"] === client["billingPostalCode"]) ??
      false,
  };

  const deleteHandle = () => {
    if (client?.clientId) {
      auth.client
        ?.client_Delete(client?.clientId ?? "", "1.0")
        .then(() => {
          toast({
            position: "bottom",
            status: "success",
            title: "Client deleted",
          });
          history.goBack();
        })
        .catch((e) => handleError(e.errors?.Error[0]));
    }
  };

  const handleResponse = (title: string) => {
    toast({
      position: "bottom",
      status: "success",
      title: title,
    });
  };

  const deactivateOnNewEmail = () => {
    var identityConfig = {
      headers: {
        "Content-Type": "application/json",
        "api-version": "1.0",
        Authorization: `Bearer ${getUserAccessToken()}`,
      },
    };

    auth.client?.user_GetUserEmail(inital.emailAddress).then((e) => {
      var userId = e.result.userId ?? "";
      axios.delete(`${identityServerURL}/api/user/${userId}`, identityConfig).then(async () => {
        auth.client
          ?.admin_Delete(userId, "1.0")
          .then(() => {
            auth.client
              ?.client_Update_Invited(inital.id ?? "", "1.0", false)
              .then(() => {
                setInvited(false);
              })
              .catch((e) => handleError(e.errors?.Error[0]));
          })
          .catch((e) => handleError(e.errors?.Error[0]));
      });
    });
  };

  const deactivateUser = async () => {
    var firstName = (document.getElementById("firstName") as HTMLInputElement).value;
    console.log(firstName);
    var email = (document.getElementById("emailAddress") as HTMLInputElement).value;

    var identityConfig = {
      headers: {
        "Content-Type": "application/json",
        "api-version": "1.0",
        Authorization: `Bearer ${getUserAccessToken()}`,
      },
    };

    auth.client?.user_GetUserEmail(email).then((e) => {
      var userId = e.result.userId ?? "";
      axios.delete(`${identityServerURL}/api/user/${userId}`, identityConfig).then(async () => {
        auth.client
          ?.admin_Delete(userId, "1.0")
          .then(() => {
            auth.client
              ?.client_Update_Invited(inital.id ?? "", "1.0", false)
              .then(() => {
                setInvited(false);
              })
              .catch((e) => handleError(e.errors?.Error[0]));
          })
          .catch((e) => handleError(e.errors?.Error[0]));
      });
    });
  };

  const inviteUser = () => {
    var firstName = (document.getElementById("firstName") as HTMLInputElement).value;
    var lastName = (document.getElementById("lastName") as HTMLInputElement).value;
    var email = (document.getElementById("emailAddress") as HTMLInputElement).value;
    var clientId = inital.id;

    const newClient: CreateUser = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      userRole: 2,
      userId: "TODO",
      clientIds: [clientId ?? ""],
    };

    var identityConfig = {
      headers: {
        "Content-Type": "application/json",
        "api-version": "1.0",
        Authorization: `Bearer ${getUserAccessToken()}`,
      },
    };

    auth.client
      ?.user_GetUserEmail(email)
      .then((e) => {
        // Email already exists
        var userId = e.result.userId ?? "";
        newClient.userId = userId;
        axios
          .put(
            `${identityServerURL}/api/user/${userId}`,
            {
              id: userId,
              email: newClient.email,
              firstName: newClient.firstName,
              lastName: newClient.lastName,
              role: "gripidle.gripportaluser",
              isEnabled: true,
            },
            identityConfig
          )
          .then(() => {
            auth.client
              ?.admin_Update(userId, "1.0", newClient)
              .then(() => {
                handleResponse("User was reactivated.");
              })
              .catch((e) => handleError(e.errors?.Error[0]));
          });
      })
      .catch((e) => {
        // Email doesn't already exist
        axios
          .post(
            `${identityServerURL}/api/user`,
            {
              email: newClient.email,
              firstName: newClient.firstName,
              lastName: newClient.lastName,
              role: "gripidle.gripportaluser",
            },
            identityConfig
          )
          .then((e) => {
            newClient.userId = e.data.id;
            auth.client
              ?.admin_Create("1.0", newClient)
              .then((r) => {
                handleResponse("User was invited.");
                history.goBack();
              })
              .catch((e) => {
                handleError(e.errors);
              });
          })
          .catch((e) => {
            if (e.response && e.response.status === 400) {
              handleError(e, "Email already in use");
            } else {
              handleError(e, "Error creating admin");
            }
          });
      });

    auth.client
      ?.client_Update_Invited(inital.id ?? "", "1.0", true)
      .then(() => {
        setInvited(true);
      })
      .catch((e) => handleError(e.errors?.Error[0]));
  };

  const emailChanged = () => {
    let emailTag = document.getElementById("emailAddress") as HTMLInputElement;

    if (emailTag != null) {
      let newEmail = emailTag.value;
      if (newEmail === inital.emailAddress) {
        setIsDifferentEmail(false);
      } else {
        setIsDifferentEmail(true);
      }
    } else {
      setIsDifferentEmail(true);
    }
  };

  const handleError = (e: Error, title?: string) => {
    if (title) {
      toast({
        position: "bottom",
        status: "error",
        title: title,
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={inital}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const newClient: CreateClientModel = {
          firstName: values.firstName,
          lastName: values.lastName,
          clientId: "",

          title: values.title,
          department: values.department,
          company: values.company,

          emailAddress: values.emailAddress,
          businessPhoneNumber: values.businessPhoneNumber,
          businessPhoneNumberExtension: values.businessPhoneNumberExtension,
          cellPhoneNumber: values.cellPhoneNumber,
          faxNumber: values.faxNumber,
          otherPhoneNumber: values.otherPhoneNumber,
          companyWebsiteUrl: values.companyWebsiteUrl,

          billingAddress: values.billingAddress,
          billingAddress2: values.billingAddress2,
          billingCity: values.billingCity,
          billingProvState: values.billingProvState,
          billingCountry: values.billingCountry,
          billingPostalCode: values.billingPostalCode,

          shippingAddress: values.shippingAddress,
          shippingAddress2: values.shippingAddress2,
          shippingCity: values.shippingCity,
          shippingProvState: values.shippingProvState,
          shippingCountry: values.shippingCountry,
          shippingPostalCode: values.shippingPostalCode,

          backupContactName: values.backupContactName,
          backupContactTitle: values.backupContactTitle,
          backupContactDepartment: values.backupContactDepartment,
          backupContactEmailAddress: values.backupContactEmailAddress,
          backupContactBusinessPhoneNumber: values.backupContactBusinessPhoneNumber,
          backupContactBusinessPhoneNumberExtension: values.backupContactBusinessPhoneNumberExtension,
          backupContactCell: values.backupContactCell,
          backupContactFax: values.backupContactFax,
          backupContactOtherPhoneNumber: values.backupContactOtherPhoneNumber,

          representativeGrip: values.representativeGrip,
          representativeDistributor: values.representativeDistributor,
          representativeDealer: values.representativeDealer,
        };

        if (values.isSameAsBilling) {
          newClient.shippingAddress = newClient.billingAddress;
          newClient.shippingAddress2 = newClient.billingAddress2;
          newClient.shippingCity = newClient.billingCity;
          newClient.shippingCountry = newClient.billingCountry;
          newClient.shippingProvState = newClient.billingProvState;
          newClient.shippingPostalCode = newClient.billingPostalCode;
        }

        if (client?.clientId) {
          if (inital.emailAddress !== values.emailAddress) {
            deactivateOnNewEmail();
          }

          const existingClient = newClient as UpdateClientModel;
          existingClient.clientId = client?.clientId;
          existingClient.isDeleted = client?.isDeleted ?? false;
          auth.client
            ?.client_Update(existingClient.clientId, "1.0", existingClient)
            .then(() => {
              handleResponse("Client was successfully updated.");
              history.goBack();
            })
            .catch((e) => handleError(e.errors?.Error[0]));
        } else {
          auth.client
            ?.client_Create("1.0", newClient)
            .then((r) => {
              handleResponse("Client was successfully created.");
              history.goBack();
            })
            .catch((e) => {
              handleError(e.errors?.Error[0], "Company already exists");
            });
        }
      }}
    >
      {({ values: v, dirty, setFieldValue }) => (
        <Box p="6" rounded="md" backgroundColor="background">
          <Form noValidate>
            <Heading size="lg" mt={2} mb={6}>
              {client?.clientId ? "Editing " : "Adding "} a Client
            </Heading>
            <SimpleGrid spacing={10} minChildWidth="500px">
              <Box>
                <Heading as="h4" size="sm" mb="2">
                  User Info
                </Heading>
                <SimpleGrid spacing={5} minChildWidth="350px">
                  <TextField title="First name" name="firstName" isRequired reference="firstName" />
                  <TextField title="Last name" name="lastName" isRequired reference="lastName" />
                  <TextField title="Company" name="company" isRequired />
                  <TextField title="Title" name="title" />
                  <TextField title="Department" name="department" />
                </SimpleGrid>

                <Heading as="h4" size="sm" mt="5" mb="2">
                  Client Contact Information
                </Heading>
                <SimpleGrid spacing={5} minChildWidth="350px">
                  <HStack>
                    <TextField title="Email address" name="emailAddress" isRequired reference="emailAddress" onChanged={emailChanged} />

                    {!invited && !isDifferentEmail && (
                      <Button
                        style={{ marginTop: "30px" }}
                        variant="solid"
                        colorScheme="gripgreen"
                        bg="gripgreen"
                        onClick={() => {
                          inviteUser();
                        }}
                      >
                        Invite
                      </Button>
                    )}
                    {invited && !isDifferentEmail && (
                      <Button
                        style={{ marginTop: "30px" }}
                        variant="solid"
                        colorScheme="gripgreen"
                        bg="gripgreen"
                        onClick={() => {
                          deactivateUser();
                        }}
                      >
                        Deactivate
                      </Button>
                    )}
                  </HStack>

                  <HStack>
                    <TextField title="Business Phone" name="businessPhoneNumber" />
                    <TextField title="Extension" name="businessPhoneNumberExtension" />
                  </HStack>
                  <TextField title="Cell Phone" name="cellPhoneNumber" />
                  <TextField title="Fax Number" name="faxNumber" />
                  <TextField title="Other" name="otherPhoneNumber" />
                </SimpleGrid>
              </Box>
              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontSize="xl" fontWeight="bold">
                        Billing Details
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <SimpleGrid spacing={5} minChildWidth="350px" mt={5}>
                      <TextField title="Address" name="billingAddress" />
                      <TextField title="Address 2" name="billingAddress2" />
                      <TextField title="City" name="billingCity" />
                      <TextField title="Province/State" name="billingProvState" maxLength={2} />
                      <TextField title="Country" name="billingCountry" />
                      <TextField title="Postal/Zip Code" name="billingPostalCode" />
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontSize="xl" fontWeight="bold">
                        Shipping Info
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Box pt={5} pb={v.isSameAsBilling ? 0 : 5}>
                      <label>
                        <Field type="checkbox" name="isSameAsBilling" />
                        &nbsp;&nbsp;<span>Same as Billing Info</span>
                      </label>
                    </Box>
                    <SimpleGrid spacing={5} minChildWidth="350px" mt={5} hidden={v.isSameAsBilling}>
                      <TextField title="Address" name="shippingAddress" hasReliance />
                      <TextField title="Address Line 2" name="shippingAddress2" hasReliance />
                      <TextField title="City" name="shippingCity" hasReliance />
                      <TextField title="Prov/State" name="shippingProvState" maxLength={2} hasReliance />
                      <TextField title="Country" name="shippingCountry" hasReliance />
                      <TextField title="Postal/Zip Code" name="shippingPostalCode" hasReliance />
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontSize="xl" fontWeight="bold">
                        Backup Contact
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <SimpleGrid spacing={5} minChildWidth="350px" mt={5}>
                      <TextField title="Name" name="backupContactName" />
                      <TextField title="Title" name="backupContactTitle" />
                      <TextField title="Department" name="backupContactDepartment" />
                      <TextField title="Email Address" name="backupContactEmailAddress" />
                      <HStack>
                        <TextField title="Business Phone" name="backupContactBusinessPhoneNumber" />
                        <TextField title="Extension" name="backupContactBusinessPhoneNumberExtension" />
                      </HStack>
                      <TextField title="Cell Phone" name="backupContactCell" />
                      <TextField title="Fax Number" name="backupContactFax" />
                      <TextField title="Other" name="backupContactOtherPhoneNumber" />
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontSize="xl" fontWeight="bold">
                        Representatives
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={5}>
                    <SimpleGrid spacing={5} minChildWidth="350px" mt={5}>
                      <TextField title="GRIP Contact Person" name="representativeGrip" />
                      <TextField title="Purchasing Distributor" name="representativeDistributor" />
                      <TextField title="Preferred Installer" name="representativeDealer" />
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </SimpleGrid>
            <ButtonGroup pt={10} spacing={5}>
              {!client?.isDeleted && (
                <Button type="submit" variant="solid" colorScheme="gripgreen" bg="gripgreen">
                  Save
                </Button>
              )}

              {isSuperAdmin() && <DeleteConfirmationDialog title="Delete" isSoftDelete={false} handleDelete={deleteHandle} />}
            </ButtonGroup>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default ClientForm;
