/**
 * Used to normalize the max length errors across the website.
 * @param title The title of the field which is invalid.
 * @param length The allowed max-length of the field.
 * @returns A general max length excdeeded message with the field name and length in place.
 */
export const FormatLengthError = (title: string, length: number) =>
  `${title} should not exceed ${length} characters in length.`;

/**
 * Used to normalize the required errors across the website.
 * @param title The title of the field which is invalid.
 * @returns A general required error message with the field name in place.
 */
export const FormatRequiredError = (title: string) =>
  `${title} is a required field.`;
