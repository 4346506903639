// React
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Grip API
import useAuth from "../../hooks/useAuth";
import { GetMediaDto } from "../../helpers/GripApi";
import { isAllowedToEdit } from "../../helpers/AuthService";
// Chakra UI
import {
  Breadcrumb,
  BreadcrumbItem,
  Box,
  Heading,
  Button,
  Text,
  Input,
  Flex,
  Spacer,
  InputGroup,
  InputLeftElement,
  IconButton,
} from "@chakra-ui/react";
import Table from "../../components/Table";
import MediaPreview from "../../components/MediaPreview";

import SearchIcon from "mdi-react/MagnifyIcon";
import DownIcon from "mdi-react/ChevronDownIcon";
import UpIcon from "mdi-react/ChevronUpIcon";

const MediaPage: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const [media, setMedia] = useState<GetMediaDto[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const [PDFSectionOpen, setPDFSectionOpen] = useState<boolean>();
  const [PhotoSectionOpen, setPhotoSectionOpen] = useState<boolean>();
  const [VideoSectionOpen, setVideoSectionOpen] = useState<boolean>();
  const [SoftwareSectionOpen, setSoftwareSectionOpen] = useState<boolean>();

  const updateSearchTerm = (event: any) => setSearchTerm(event.target.value.toLowerCase());

  const columns = React.useMemo(
    () => [
      {
        accessor: "filePath",
        Cell: (props: any) => {
          return <MediaPreview src={props.row.original.filePath} filename={props.row.original.fileNameOriginal} maxWidth={200} />;
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    []
  );

  const filteredMedia = () => {
    if (media) {
      if (searchTerm) {
        return media.filter((x) => x.name?.toLowerCase().includes(searchTerm) || x.description?.toLowerCase().includes(searchTerm));
      } else {
        return media;
      }
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    auth.client?.media_GetAll("1.0").then((response) => {
      setMedia(response.result);
    });

    setPDFSectionOpen(false);
    setPhotoSectionOpen(false);
    setVideoSectionOpen(false);
    setSoftwareSectionOpen(false);
  }, [auth.client]);

  const toCreatePage = () => {
    history.push("/media-manager/create");
  };

  const handleRowClick = (row: GetMediaDto) => {
    if (isAllowedToEdit()) {
      history.push("/media-manager/" + row.mediaId);
    }
  };

  return (
    <>
      <Flex w="100%">
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
          <BreadcrumbItem>
            <Text>Media Manager</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
        {isAllowedToEdit() && (
          <Button colorScheme="gripgreen" bg="gripgreen" onClick={toCreatePage}>
            Add Media
          </Button>
        )}
      </Flex>
      <Box bg="white" borderRadius="lg" mb={5} backgroundColor="background">
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="grey.100" />} />
          <Input value={searchTerm} onChange={updateSearchTerm} placeholder="Search..." />
        </InputGroup>
      </Box>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6} onClick={() => setPDFSectionOpen(!PDFSectionOpen)}>
          <Flex w="100%">
            All PDFs
            <Spacer />
            {!PDFSectionOpen && (
              <IconButton variant="ghost" colorScheme="red" size="sm" icon={<DownIcon color="grey.100" />} aria-label="Open Section" />
            )}
            {PDFSectionOpen && (
              <IconButton variant="ghost" colorScheme="red" size="sm" icon={<UpIcon color="grey.100" />} aria-label="Close Section" />
            )}
          </Flex>
        </Heading>
        {PDFSectionOpen && <Table data={filteredMedia().filter((x) => x.mediaType === 0)} onRowClicked={handleRowClick} columns={columns} />}
      </Box>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6} onClick={() => setPhotoSectionOpen(!PhotoSectionOpen)}>
          <Flex w="100%">
            All Photos
            <Spacer />
            {!PhotoSectionOpen && (
              <IconButton variant="ghost" colorScheme="red" size="sm" icon={<DownIcon color="grey.100" />} aria-label="Open Section" />
            )}
            {PhotoSectionOpen && (
              <IconButton variant="ghost" colorScheme="red" size="sm" icon={<UpIcon color="grey.100" />} aria-label="Close Section" />
            )}
          </Flex>
        </Heading>
        {PhotoSectionOpen && <Table data={filteredMedia().filter((x) => x.mediaType === 1)} onRowClicked={handleRowClick} columns={columns} />}
      </Box>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6} onClick={() => setVideoSectionOpen(!VideoSectionOpen)}>
          <Flex w="100%">
            All Videos
            <Spacer />
            {!VideoSectionOpen && (
              <IconButton variant="ghost" colorScheme="red" size="sm" icon={<DownIcon color="grey.100" />} aria-label="Open Section" />
            )}
            {VideoSectionOpen && (
              <IconButton variant="ghost" colorScheme="red" size="sm" icon={<UpIcon color="grey.100" />} aria-label="Close Section" />
            )}
          </Flex>
        </Heading>
        {VideoSectionOpen && <Table data={filteredMedia().filter((x) => x.mediaType === 2)} onRowClicked={handleRowClick} columns={columns} />}
      </Box>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6} onClick={() => setSoftwareSectionOpen(!SoftwareSectionOpen)}>
          <Flex w="100%">
            All Software
            <Spacer />
            {!SoftwareSectionOpen && (
              <IconButton variant="ghost" colorScheme="red" size="sm" icon={<DownIcon color="grey.100" />} aria-label="Open Section" />
            )}
            {SoftwareSectionOpen && (
              <IconButton variant="ghost" colorScheme="red" size="sm" icon={<UpIcon color="grey.100" />} aria-label="Close Section" />
            )}
          </Flex>
        </Heading>
        {SoftwareSectionOpen && <Table data={filteredMedia().filter((x) => x.mediaType === 3)} onRowClicked={handleRowClick} columns={columns} />}
      </Box>
    </>
  );
};

export default MediaPage;
