import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
  Textarea,
  useToast,
  Flex,
  Spacer,
  ButtonGroup,
} from "@chakra-ui/react";
import React from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { NavLink } from "react-router-dom";
import { FileParameter } from "../../helpers/GripApi";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import ExitButtonWithConfirmation from "../../components/ExitButtonWithConfirmation";
import MediaPreview from "../../components/MediaPreview";

interface Media {
  data: any;
  fileName: string;
  name: string;
  description: string;
  objectURL: any;
}

const MediaCreatePage: React.FC = () => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const [media, setMedia] = React.useState<Media[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    const mediaFiles = [...media];
    acceptedFiles.forEach((acceptedFile) => {
      // only add if that file doesn't exist in state
      if (!mediaFiles.some((file) => file.fileName === acceptedFile.name)) {
        const newMedia: Media = {
          data: acceptedFile,
          fileName: acceptedFile.name,
          name: acceptedFile.name.substr(0, acceptedFile.name.lastIndexOf(".")),
          description: "",
          objectURL: URL.createObjectURL(acceptedFile),
        };
        mediaFiles.push(newMedia);
      }
    });
    setMedia(mediaFiles);
  };
  const removeFile = (fileName: string) => {
    const mediaFiles = [...media];
    setMedia(mediaFiles.filter((file) => file.fileName !== fileName));
  };
  const updateName = (name: string, index: number) => {
    const mediaFiles = [...media];
    mediaFiles[index].name = name;
    setMedia(mediaFiles);
  };
  const updateDescription = (description: string, index: number) => {
    const mediaFiles = [...media];
    mediaFiles[index].description = description;
    setMedia(mediaFiles);
  };
  const upload = () => {
    const mediaFiles = [...media];
    let successful: string[] = [];

    setIsLoading(true);

    mediaFiles.forEach((mediaFile) => {
      const fileParameter: FileParameter = {
        data: mediaFile.data,
        fileName: mediaFile.fileName,
      };

      auth.client
        ?.media_Create("1.0", mediaFile.name, mediaFile.description, fileParameter)
        .then((r) => {
          successful.push(mediaFile.fileName);
          toast({
            position: "bottom",
            status: "success",
            title: "Media added",
          });
          history.push("/media-manager");
        })
        .catch((error) => {
          console.error(error);
          toast({
            position: "bottom",
            status: "error",
            title: "There was an error creating the Media.",
          });
          successful.forEach((fileName) => {
            setMedia(mediaFiles.filter((file) => file.fileName !== fileName));
          });
        })
        .finally(() => setIsLoading(false));
    });
  };

  return (
    <>
      <Flex w="100%">
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
          <BreadcrumbItem>
            <BreadcrumbLink as={NavLink} to="/media-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
              Media Manager
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>Create Media</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
        <Box>
          {media.length > 0 && (
            <ButtonGroup spacing={5}>
              <Button colorScheme="gripgreen" backgroundColor="gripgreen" onClick={upload} isLoading={isLoading}>
                Save
              </Button>
              <ExitButtonWithConfirmation onConfirm={history.goBack} isDirty={true} />
            </ButtonGroup>
          )}
        </Box>
      </Flex>
      <Dropzone onDrop={handleDrop} accept="image/gif, image/jpeg, image/png, image/svg+xml, image/webp, video/mp4, application/pdf, .zip, .exe">
        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
          const classStyle = isDragAccept ? "accept" : isDragReject ? "reject" : "";
          return (
            <div {...getRootProps({ className: `dropzone ${classStyle}` })}>
              <Box my={10} py={10}>
                <input {...getInputProps()} />
                <p>Drag and drop files, or click to select files</p>
              </Box>
            </div>
          );
        }}
      </Dropzone>
      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
        {media.map((file, i) => {
          return (
            <GridItem colSpan={1}>
              <Box key={file.fileName} maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" bg="white">
                <Center>
                  <MediaPreview objectURL={file.objectURL} filename={file.fileName} />
                </Center>
                <Box p={4}>
                  <Box mb={3}>
                    <FormLabel>Filename</FormLabel>
                    <Input variant="filled" defaultValue={file.fileName} readOnly={true} />
                  </Box>
                  <Box mb={3}>
                    <FormLabel>Name</FormLabel>
                    <Input placeholder="Name" defaultValue={file.name} onChange={(e) => updateName(e.target.value, i)} />
                  </Box>
                  <Box mb={3}>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      placeholder="Description"
                      defaultValue={file.description}
                      onChange={(e) => updateDescription(e.target.value, i)}
                    ></Textarea>
                  </Box>
                  <DeleteConfirmationDialog
                    handleDelete={() => {
                      removeFile(file.fileName);
                    }}
                    title="Remove Media"
                  >
                    Remove
                  </DeleteConfirmationDialog>
                </Box>
              </Box>
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
};

export default MediaCreatePage;
