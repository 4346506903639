import React, { FunctionComponent, useState } from "react";
import { Heading, Box } from "@chakra-ui/layout";
import { Button, Breadcrumb, BreadcrumbItem, Text, Flex, Spacer, Radio, RadioGroup, Stack } from "@chakra-ui/react";

import { useHistory } from "react-router";
import { GetUserDto } from "../../helpers/GripApi";

import useAuth from "../../hooks/useAuth";
import Table from "../../components/Table";

const AdminManagerPage: FunctionComponent = () => {
  const auth = useAuth();
  const history = useHistory();

  const [admins, setAdmins] = useState<GetUserDto[]>([]);
  const [value, setValue] = useState<string>("2");

  const columns = React.useMemo(
    () => [
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Deactivated",
        accessor: "isDeleted",
      },
    ],
    []
  );

  const onOpen = () => {
    history.push("/access-manager/grip-admin/create");
  };

  React.useEffect(() => {
    auth.client?.admin_GetAll("1.0", 1).then((response) => {
      setAdmins(response.result);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (row: GetUserDto) => {
    history.push({
      pathname: "/access-manager/grip-admin/" + row.userId,
      state: row,
    });
  };

  return (
    <>
      <Flex w="100%">
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
          <BreadcrumbItem>
            <Text>Admin Manager</Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>Admins</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
        <Button colorScheme="greegripgreenn" bg="gripgreen" onClick={onOpen}>
          Add Admin
        </Button>
      </Flex>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6}>
          Admins
        </Heading>
        <Box p={4} borderRadius="lg" mb={5}>
          <RadioGroup onChange={setValue} value={value}>
            <Stack direction="row">
              <Radio value="1">All Admins</Radio>
              <Radio value="2">Activate Admins</Radio>
              <Radio value="3">Deactivated Admins</Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Table
          onRowClicked={handleRowClick}
          data={admins.filter(
            (e) => value === "1" || (value === "2" && e.isDeleted.toLowerCase() === "no") || (value === "3" && e.isDeleted.toLowerCase() === "yes")
          )}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default AdminManagerPage;
