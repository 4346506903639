// React
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
// Chakra UI
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, useToast } from "@chakra-ui/react";
// Grip API
import useAuth from "../../hooks/useAuth";
import {
  GetAnalysisManualDto,
  GetIgnitionSheetDto,
  GetKitInstructionsDto,
  GetManualsDto,
  GetServiceBulletinDto,
  GetSoftwareDto,
  GetGripControllerApplicationDto,
  GetTrainingDocumentDto,
} from "../../helpers/GripApi";
// Components
import MediaForm from "../../forms/MediaForm";
import AnalysisManualForm from "../../forms/Resources/AnalysisManualForm";
// import IgnitionSheetForm from "../../forms/Resources/IgnitionSheetForm";
import KitInstructionsForm from "../../forms/Resources/KitInstructionsForm";
import ManualsForm from "../../forms/Resources/ManualsForm";
import ServiceBulletinForm from "../../forms/Resources/ServiceBulletinForm";
import SoftwareForm from "../../forms/Resources/SoftwareForm";
import TrainingDocumentForm from "../../forms/Resources/TrainingDocumentsForm";
import GripControllerApplicationsForm from "../../forms/Resources/GripControllerApplicationsForm";

const MediaEditPage: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const params: any = useParams();
  const toast = useToast();

  const mediaId = params.id;

  const [analysisManual, setAnalysisManuals] = useState<GetAnalysisManualDto>();
  // const [ignitionSheet, setIgnitionSheet] = useState<GetIgnitionSheetDto>();
  const [kitInstructions, setKitInstructions] = useState<GetKitInstructionsDto>();
  const [manuals, setManuals] = useState<GetManualsDto>();
  const [serviceBulletin, setServiceBulletin] = useState<GetServiceBulletinDto>();
  const [software, setSoftware] = useState<GetSoftwareDto>();
  const [trainingDocuments, setTrainingDocuments] = useState<GetTrainingDocumentDto>();
  const [gripControllerApplication, setGripControllerApplication] = useState<GetGripControllerApplicationDto>();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("type");
    if (mediaId && myParam) {
      if (myParam.toLowerCase() === "analysismanual") {
        auth.client
          ?.analysisManual_Get(mediaId, "1.0")
          .then((response) => {
            setAnalysisManuals(response.result);
          })
          .catch((error) => {
            toast({
              position: "bottom",
              status: "error",
              title: "There was an error fetching the Media.",
            });
            history.goBack();
          });
        // } else if (myParam.toLowerCase() === "ignitionsheet") {
        //   auth.client
        //     ?.ignitionSheet_Get(mediaId, "1.0")
        //     .then((response) => {
        //       setIgnitionSheet(response.result);
        //     })
        //     .catch((error) => {
        //       toast({
        //         position: "bottom",
        //         status: "error",
        //         title: "There was an error fetching the Media.",
        //       });
        //       history.goBack();
        //     });
      } else if (myParam.toLowerCase() === "trainingdocuments") {
        auth.client
          ?.trainingDocument_Get(mediaId, "1.0")
          .then((response) => {
            setTrainingDocuments(response.result);
          })
          .catch((error) => {
            toast({
              position: "bottom",
              status: "error",
              title: "There was an error fetching the Media.",
            });
            history.goBack();
          });
      } else if (myParam.toLowerCase() === "kitinstructions") {
        auth.client
          ?.kitInstructions_Get(mediaId, "1.0")
          .then((response) => {
            setKitInstructions(response.result);
          })
          .catch((error) => {
            toast({
              position: "bottom",
              status: "error",
              title: "There was an error fetching the Media.",
            });
            history.goBack();
          });
      } else if (myParam.toLowerCase() === "manual") {
        auth.client
          ?.manuals_Get(mediaId, "1.0")
          .then((response) => {
            setManuals(response.result);
          })
          .catch((error) => {
            toast({
              position: "bottom",
              status: "error",
              title: "There was an error fetching the Media.",
            });
            history.goBack();
          });
      } else if (myParam.toLowerCase() === "servicebulletin") {
        auth.client
          ?.serviceBulletins_Get(mediaId, "1.0")
          .then((response) => {
            setServiceBulletin(response.result);
          })
          .catch((error) => {
            toast({
              position: "bottom",
              status: "error",
              title: "There was an error fetching the Media.",
            });
            history.goBack();
          });
      } else if (myParam.toLowerCase() === "software") {
        auth.client
          ?.software_Get(mediaId, "1.0")
          .then((response) => {
            setSoftware(response.result);
          })
          .catch((error) => {
            toast({
              position: "bottom",
              status: "error",
              title: "There was an error fetching the Media.",
            });
            history.goBack();
          });
      } else if (myParam.toLowerCase() === "gripcontrollerapplication") {
        auth.client
          ?.gripControllerApplication_Get(mediaId, "1.0")
          .then((response) => {
            setGripControllerApplication(response.result);
          })
          .catch((error) => {
            toast({
              position: "bottom",
              status: "error",
              title: "There was an error fetching the Media.",
            });
            history.goBack();
          });
      }
    } else {
    }
  }, [auth.client, history, mediaId, toast]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/resource-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Resource Manager
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Edit Resource</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      {/* a {analysisManual} */}
      {analysisManual?.id && <AnalysisManualForm analysisManual={analysisManual} />}
      {/* {ignitionSheet?.id && <IgnitionSheetForm ignitionSheet={ignitionSheet} />} */}
      {kitInstructions?.id && <KitInstructionsForm kitInstructions={kitInstructions} />}
      {manuals?.id && <ManualsForm manuals={manuals} />}
      {serviceBulletin?.id && <ServiceBulletinForm serviceBulletin={serviceBulletin} />}
      {software?.id && <SoftwareForm software={software} />}
      {gripControllerApplication?.id && <GripControllerApplicationsForm software={gripControllerApplication} />}
      {trainingDocuments?.id && <TrainingDocumentForm trainingDocument={trainingDocuments} />}
    </>
  );
};

export default MediaEditPage;
