// React
import React, { FunctionComponent, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { GetKitDto } from "../../helpers/GripApi";
import { isAllowedToEdit } from "../../helpers/AuthService";
import { Heading, Box, Button, Breadcrumb, BreadcrumbItem, Text, useToast, Flex, Spacer, ButtonGroup } from "@chakra-ui/react";
import Table from "../../components/Table";
import useAuth from "../../hooks/useAuth";
import KitImportFileDialog from "../../components/KitImportFileDialog";

const KitManagerPage: FunctionComponent = () => {
  const auth = useAuth();
  const history = useHistory();
  const toast = useToast();
  const [kits, setKits] = useState<GetKitDto[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "Make",
        accessor: "make",
      },
      {
        Header: "Model",
        accessor: "model",
      },
      {
        Header: "Series",
        accessor: "series",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Fuel Type",
        accessor: "fuelType.name",
      },
      {
        Header: "Ignition Type",
        accessor: "ignitionType.name",
      },
      {
        Header: "Controller Program",
        accessor: "controllerProgram",
      },
      {
        Header: "Screen Program",
        accessor: "screenProgram",
      },
      {
        Header: "Wiring Detail Sheet",
        accessor: "wiringDocument",
      },
      {
        Header: "Hardware Kit",
        accessor: "hardwareKit",
      },
    ],
    []
  );

  const onOpen = () => {
    history.push("/kit-manager/create");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => loadKits(), [auth.client]);

  const onCloseDialog = () => {
    loadKits();
    setOpenDialog(!openDialog);
  };

  const handleRowClick = (row: GetKitDto) => {
    history.push("/kit-manager/" + row.kitId);
  };

  const loadKits = () => {
    auth.client?.kit_GetAll(undefined, "1.0").then((response) => {
      setKits(response.result);
    });
  };

  const handleError = useCallback(
    (title?: string) => {
      if (title) {
        toast({
          position: "bottom",
          status: "error",
          title: title,
        });
      }
    },
    [toast]
  );

  const exportKits = () => {
    auth.client
      ?.kit_Export("1.0")
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.result.fileName ?? "GRIP Kits - " + new Date().toString() + ".xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => handleError(e.errors?.Error[0]));
  };

  return (
    <>
      <Flex w="100%">
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
          <BreadcrumbItem>
            <Text>Kit Manager</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
        <ButtonGroup spacing={5}>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={() => setOpenDialog(!openDialog)}>
              Import
            </Button>
          )}
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={exportKits}>
              Export
            </Button>
          )}
          <input type="file" id="file" style={{ display: "none" }} />
        </ButtonGroup>
      </Flex>
      {/* start:: Breadcrumb Links */}

      {/* end:: Breadcrumb Links */}

      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6}>
          All Kits
        </Heading>
        {/* start:: All Kits Table */}
        <Table onRowClicked={handleRowClick} data={kits} columns={columns} />
        {/* end:: All Kits Table */}

        <KitImportFileDialog openDialog={openDialog} onCloseDialog={onCloseDialog} />
      </Box>
    </>
  );
};

export default KitManagerPage;
