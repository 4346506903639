import React from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header";
import { useState } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";

const smVariant = { navigation: "drawer", navigationButton: true };
const mdVariant = { navigation: "sidebar", navigationButton: false };

const DashboardLayout: React.FC = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  return (
    <>
      <Sidebar variant={variants?.navigation} isOpen={isSidebarOpen} onClose={toggleSidebar} />
      <Box ml={!variants?.navigationButton ? 256 : "false"} height="100vh">
        <Header title="Hello" showSidebarButton={variants?.navigationButton} onShowSidebar={toggleSidebar} />
        <Box p={10} height="calc(100vh - 81px)" backgroundColor="background">
          {children}
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
