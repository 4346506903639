import { FormLabel, Box, Button, Heading, useToast, SimpleGrid, ButtonGroup, Select, VStack } from "@chakra-ui/react";

import axios from "axios";

import * as React from "react";
import TextField from "../../components/TextField";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { CreateTrainingDocumentModel, GetTrainingDocumentDto, UpdateTrainingDocumentModel, GetMediaDto } from "../../helpers/GripApi";
import useAuth from "../../hooks/useAuth";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import { useHistory } from "react-router-dom";
import ExitButtonWithConfirmation from "../../components/ExitButtonWithConfirmation";

import { identityServerURL } from "../../helpers/Settings";
import { getUserAccessToken } from "../../helpers/AuthService";
import MediaPreview from "../../components/MediaPreview";

type AnalysisFormProps = {
  trainingDocument?: GetTrainingDocumentDto;
};

const TrainingDocumentForm: React.FC<AnalysisFormProps> = ({ trainingDocument }) => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const [media, setMedia] = React.useState<GetMediaDto[]>();
  const [logo, setLogo] = React.useState<GetMediaDto>();
  const [file, setFile] = React.useState<GetMediaDto>();

  const inital = {
    logo: trainingDocument?.logo.mediaId || "",
    description: trainingDocument?.description || "",
    product: trainingDocument?.product || "",
    rev: trainingDocument?.rev || "",
    file: trainingDocument?.media.mediaId || "",
  };

  React.useEffect(() => {
    if (trainingDocument?.logo) {
      setLogo(trainingDocument?.logo);
    }
    if (trainingDocument?.media) {
      setFile(trainingDocument?.media);
    }

    auth.client
      ?.media_GetAll("1.0")
      .then((response) => {
        setMedia(response.result);

        if (response.result.length > 0) {
          if (!trainingDocument?.logo) {
            setLogo(response.result[0]);
          }
          if (!trainingDocument?.media) {
            setFile(response.result[0]);
          }
        }
      })
      .catch((error) => {});
  }, [auth.client]);

  const deleteHandle = () => {
    if (trainingDocument?.id) {
      auth.client
        ?.trainingDocument_Delete(trainingDocument?.id, "1.0")
        .then(() => {
          toast({
            position: "bottom",
            status: "success",
            title: "Training Document deleted",
          });
          history.goBack();
        })
        .catch((e) => handleError(e.errors?.Error[0]));
    }
  };

  const handleResponse = (title: string) => {
    toast({
      position: "bottom",
      status: "success",
      title: title,
    });
  };

  const handleError = (e: Error, title?: string) => {
    if (title) {
      toast({
        position: "bottom",
        status: "error",
        title: title,
      });
    }
  };

  const logoSelected = (index: string) => {
    let logo = media?.filter((e) => e.mediaId === index);
    if (logo && logo.length > 0) {
      setLogo(logo[0]);
    }
  };

  const fileSelected = (index: string) => {
    let logo = media?.filter((e) => e.mediaId === index);
    if (logo && logo.length > 0) {
      setFile(logo[0]);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={inital}
      onSubmit={(values) => {
        let firstMedia;
        if (media && media.length > 0) {
          firstMedia = media[0];
        }
        const newClient: CreateTrainingDocumentModel = {
          logoId: logo?.mediaId ?? firstMedia?.mediaId ?? "",
          description: values.description,
          product: values.product,
          rev: values.rev,
          mediaId: file?.mediaId ?? firstMedia?.mediaId ?? "",
        };

        if (trainingDocument?.id) {
          const existingClient = newClient as UpdateTrainingDocumentModel;
          existingClient.id = trainingDocument?.id;

          auth.client
            ?.trainingDocument_Update(existingClient.id, "1.0", existingClient)
            .then(() => {
              handleResponse("Manual was successfully updated.");
              history.goBack();
            })
            .catch((e) => handleError(e.errors?.Error[0]));
        } else {
          auth.client
            ?.trainingDocument_Create("1.0", newClient)
            .then((r) => {
              handleResponse("Manual was successfully created.");
              history.goBack();
            })
            .catch((e) => handleError(e.errors));
        }
      }}
    >
      {({ values: v, dirty, setFieldValue }) => (
        <Box p="6" rounded="md" backgroundColor="background">
          <Form noValidate>
            <Heading size="lg" mt={2} mb={6}>
              {trainingDocument?.id ? "Editing " : "Adding "} Training Documents
            </Heading>
            <SimpleGrid spacing={10} minChildWidth="500px">
              <Box>
                <SimpleGrid spacing={5} minChildWidth="350px">
                  <VStack alignItems="left">
                    <FormLabel>Image</FormLabel>
                    {logo && <MediaPreview maxWidth={100} src={logo.filePath} filename={logo.fileNameOriginal} />}
                    <Select value={logo?.mediaId} onChange={(e) => logoSelected(e.target.value)} isRequired>
                      {media &&
                        media.map(function (media, index) {
                          return (
                            <option key={media?.mediaId} value={media?.mediaId}>
                              {media?.fileNameOriginal}
                            </option>
                          );
                        })}
                    </Select>
                    <TextField title="Product" name="product" />
                    <TextField title="Revision" name="rev" />
                    <TextField title="Description" name="description" />
                    <FormLabel>File</FormLabel>
                    {file && <MediaPreview maxWidth={100} src={file.filePath} filename={file.fileNameOriginal} />}

                    <Select value={file?.mediaId} onChange={(e) => fileSelected(e.target.value)} isRequired>
                      {media &&
                        media.map(function (media, index) {
                          return (
                            <option key={media?.mediaId} value={media?.mediaId}>
                              {media?.fileNameOriginal}
                            </option>
                          );
                        })}
                    </Select>
                  </VStack>
                </SimpleGrid>
              </Box>
            </SimpleGrid>
            <ButtonGroup pt={10} spacing={5}>
              <Button type="submit" variant="solid" colorScheme="gripgreen" bg="gripgreen">
                Save
              </Button>

              <ExitButtonWithConfirmation onConfirm={history.goBack} isDirty={dirty} />
              {trainingDocument?.id && <DeleteConfirmationDialog handleDelete={deleteHandle} />}
            </ButtonGroup>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default TrainingDocumentForm;
