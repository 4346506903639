import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  useToast,
  Text,
  ButtonGroup,
  GridItem,
  Grid,
  VStack,
  Select,
  HStack,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import * as React from "react";
import TextArea from "../components/TextArea";
import TextField from "../components/TextField";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { CreatePartModel, GetMediaDto, GetPartCategoryDto, GetPartDto, UpdatePartModel } from "../helpers/GripApi";
import { isAllowedToEdit } from "../helpers/AuthService";
import useAuth from "../hooks/useAuth";
import SelectField from "../components/SelectField";
import AmountField from "../components/AmountField";
import { useHistory } from "react-router-dom";
import DeleteConfirmationDialog from "../components/DeleteConfirmationDialog";
import ExitButtonWithConfirmation from "../components/ExitButtonWithConfirmation";
import MediaPreview from "../components/MediaPreview";
import SelectField2, { SelectFieldOptionProps } from "../components/SelectField2";

import TrashIcon from "mdi-react/DeleteIcon";

type PartFormProps = {
  part?: GetPartDto;
};

const validationSchema = yup.object().shape({
  partNumber: yup.string().max(50, "Max length 50 characers").required("Part number is required"),
  partCategoryId: yup.string().nullable(),
  description: yup.string().max(500, "Max length 500 characers").required("A short description is required."),
  unitOfMeasure: yup.string().max(10).required("Unit of Measure is required"),
  distributorCAD: yup.number().required("Distributor CAD is required"),
  distributorUSD: yup.number().required("Distributor USD is required"),
  installerCAD: yup.number().required("Installer CAD is required"),
  installerUSD: yup.number().required("Installer USD is required"),
  msrpCAD: yup.number().required("MSRP CAD is required"),
  msrpUSD: yup.number().required("MSRP USD is required"),
  partStatus: yup.number().required("Part Status is required"),
  troubleshootTime: yup.number().integer("Invalid time").min(0).required("Troubleshoot time is required"),
  functionTestTime: yup.number().integer("Invalid time").min(0).required("Function test time is required"),
  standardRepairTime: yup.number().integer("Invalid time").min(0).required("Standard repair time is required"),
  warrantyTimeAllowance: yup.number().integer("Invalid time").min(0).required("Warranty duration is required"),
});

const PartForm: React.FC<PartFormProps> = ({ part }) => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const [addMedia, setAddMedia] = React.useState<boolean>();
  const [media, setMedia] = React.useState<GetMediaDto[]>();
  const [selectedMedia, setSelectedMedia] = React.useState<GetMediaDto>();
  const [partMedia, setPartMedia] = React.useState<GetMediaDto[]>();

  const partStatus = [
    { label: "Draft", value: 0 },
    { label: "Active", value: 1 },
  ];

  const [catetories, setCategories] = React.useState<GetPartCategoryDto[]>([]);

  const unitsOfMeasure: SelectFieldOptionProps[] = [
    { value: "BAG", label: "BAG" },
    { value: "EA", label: "EA" },
    { value: "EACH", label: "EACH" },
    { value: "FT", label: "FT" },
    { value: "IN", label: "IN" },
    { value: "LT", label: "LT" },
    { value: "PKG", label: "PKG" },
  ];

  React.useEffect(() => {
    auth.client?.partCategory_GetAll("1.0").then((response) => {
      setCategories(response.result);
    });

    setPartMedia(part?.media ?? []);
  }, [part, auth.client]);

  const initialValues: GetPartDto = {
    partNumber: part?.partNumber!,
    partCategoryId: part?.partCategoryId!,
    description: part?.description || "",
    unitOfMeasure: part?.unitOfMeasure,
    distributorCAD: part?.distributorCAD || undefined,
    distributorUSD: part?.distributorUSD || undefined,
    installerCAD: part?.installerCAD || undefined,
    installerUSD: part?.installerUSD || undefined,
    msrpCAD: part?.msrpCAD || undefined,
    msrpUSD: part?.msrpUSD || undefined,
    partStatus: part?.partStatus!,
    troubleshootTime: part?.troubleshootTime,
    functionTestTime: part?.functionTestTime,
    standardRepairTime: part?.standardRepairTime,
    warrantyTimeAllowance: part?.warrantyTimeAllowance,
    media: part?.media,
  };

  const deleteHandle = () => {
    if (part?.partNumber) {
      auth.client?.part_Delete(part.partNumber, "1.0").then(() => {
        history.goBack();
      });
    }
  };

  const handleResponse = (warranty: GetPartDto | null, title: string) => {
    toast({
      position: "bottom",
      status: "success",
      title: title,
    });
  };
  const handleError = (title?: string) => {
    if (title) {
      toast({
        position: "bottom",
        status: "error",
        title: title,
      });
    }
  };

  const addMediaClicked = () => {
    setAddMedia(true);
    loadMedia();
  };

  const addMediaToPartCancelClicked = () => {
    setSelectedMedia(undefined);
    setAddMedia(false);
  };

  const addMediaToPartClicked = () => {
    if (selectedMedia) {
      if (partMedia) {
        partMedia.push(selectedMedia);
        setPartMedia(partMedia);
      } else {
        setPartMedia([selectedMedia]);
      }
    }
    setSelectedMedia(undefined);
    setAddMedia(false);
  };

  const loadMedia = () => {
    auth.client?.media_GetAll("1.0").then((response) => {
      let media = response.result.filter((e) => partMedia && partMedia.filter((x) => x.mediaId === e.mediaId).length === 0);
      setMedia(media);
      if (media.length > 0) {
        setSelectedMedia(media[0]);
      }
    });
  };

  const mediaSelected = (index: any) => {
    if (media) {
      setSelectedMedia(media[index]);
    }
  };

  const removeMedia = (index: number) => {
    let medias = [] as GetMediaDto[];
    let x = initialValues.media ?? [];
    x.forEach((media, index1) => {
      if (index !== index1) {
        medias.push(media);
      }
    });
    setPartMedia(medias);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        if (part?.partNumber) {
          const existingPart = values as UpdatePartModel;

          existingPart.mediaIds = []; // ["0D664BE7-4CB4-4B2E-B0F1-08D93109F42D", "EB970D37-5C04-498D-B0F2-08D93109F42D"];

          if (partMedia) {
            partMedia.forEach((media) => {
              if (media.mediaId) {
                existingPart.mediaIds.push(media.mediaId);
              }
            });
          }

          existingPart.partCategoryId = part?.partCategoryId!;
          auth.client
            ?.part_Update(part.partNumber, "1.0", existingPart)
            .then(() => {
              handleResponse(null, "Part updated");
              resetForm();
              history.goBack();
            })
            .catch((e) => handleError(e.errors?.Error[0]));
        } else {
          const newPart = values as CreatePartModel;
          newPart.mediaIds = []; // ["0D664BE7-4CB4-4B2E-B0F1-08D93109F42D", "EB970D37-5C04-498D-B0F2-08D93109F42D"];
          if (partMedia) {
            partMedia.forEach((media) => {
              if (media.mediaId) {
                newPart.mediaIds.push(media.mediaId);
              }
            });
          }
          auth.client
            ?.part_Create("1.0", newPart)
            .then(() => {
              handleResponse(null, "Part added");
              resetForm();
              history.goBack();
            })
            .catch((e) => handleError(e.errors?.Error[0]));
        }
      }}
    >
      {({ values, dirty }) => (
        <>
          <Form noValidate>
            <Box p="6" rounded="lg" backgroundColor="background">
              <Heading size="lg" mt={2} mb={6}>
                {part?.partNumber ? "Edit Part" : "Add Part"}
              </Heading>
              <Text fontSize="xl" pb={5} fontWeight="bold">
                Information
              </Text>
              <Box pb={5}>
                <TextField title="Part number" name="partNumber" maxLength={50} isRequired isReadOnly={!isAllowedToEdit()} />
              </Box>
              <TextArea placeholder="Description" name="description" maxLength={500} isReadOnly={!isAllowedToEdit()} />
              <SimpleGrid columns={3} spacing={10} mt="5">
                <SelectField placeholder="Part status" name="partStatus" partStatus={partStatus} />
                <SelectField placeholder="Product Code" name="partCategoryId" isRequired={false} category={catetories} />
                <SelectField2 label="Unit of Measure" name="unitOfMeasure" options={unitsOfMeasure} />
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={10}>
                <Box>
                  <Box pb={5}>
                    <Text py={5} fontWeight="bold">
                      Pricing
                    </Text>
                    <SimpleGrid columns={2} spacing={10}>
                      <AmountField placeholder="Distributor CAD" name="distributorCAD" />
                      <AmountField placeholder="Distributor USD" name="distributorUSD" />
                    </SimpleGrid>
                  </Box>
                  <Box pb={5}>
                    <SimpleGrid columns={2} spacing={10}>
                      <AmountField placeholder="Installer CAD" name="installerCAD" />
                      <AmountField placeholder="Installer USD" name="installerUSD" />
                    </SimpleGrid>
                  </Box>
                  <Box pb={5}>
                    <SimpleGrid columns={2} spacing={10}>
                      <AmountField placeholder="MSRP CAD" name="msrpCAD" />
                      <AmountField placeholder="MSRP USD" name="msrpUSD" />
                    </SimpleGrid>
                  </Box>
                  <Text py={5} fontWeight="bold">
                    Timing
                  </Text>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <TextField
                        type="number"
                        title="Troubleshoot Time (in minutes)"
                        name="troubleshootTime"
                        isRequired={false}
                        isReadOnly={!isAllowedToEdit()}
                      />
                    </Box>
                    <Box pb={5}>
                      <TextField
                        type="number"
                        title="Function Test Time (in minutes)"
                        name="functionTestTime"
                        isRequired={false}
                        isReadOnly={!isAllowedToEdit()}
                      />
                    </Box>
                  </SimpleGrid>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <TextField
                        type="number"
                        title="Standard Repair Time (in minutes)"
                        name="standardRepairTime"
                        isRequired={false}
                        isReadOnly={!isAllowedToEdit()}
                      />
                    </Box>
                    <Box pb={5}>
                      <TextField
                        type="number"
                        title="Warranty Time Allowance (in months)"
                        name="warrantyTimeAllowance"
                        isRequired={false}
                        isReadOnly={!isAllowedToEdit()}
                      />
                    </Box>
                  </SimpleGrid>
                </Box>
                <Box pt={5}>
                  <Flex w="100%" justifyItems="center" justifyContent="center" pb={5}>
                    <Box>
                      <Text fontSize="xl" fontWeight="bold">
                        Media
                      </Text>
                    </Box>
                    <Spacer />
                    {isAllowedToEdit() && (
                      <Button colorScheme="gripgreen" bg="gripgreen" onClick={addMediaClicked}>
                        Add Media
                      </Button>
                    )}
                  </Flex>

                  {addMedia && (
                    <>
                      <VStack align="left">
                        <Select onChange={(e) => mediaSelected(e.target.value)} isRequired>
                          {media &&
                            media.map(function (media, index) {
                              return (
                                <option key={media?.mediaId} value={index}>
                                  {media?.fileNameOriginal}
                                </option>
                              );
                            })}
                        </Select>

                        {isAllowedToEdit() && (
                          <Button colorScheme="gripgreen" backgroundColor="gripgreen" onClick={addMediaToPartClicked}>
                            Add Media to part
                          </Button>
                        )}

                        <Button colorScheme="red" onClick={addMediaToPartCancelClicked}>
                          Cancel
                        </Button>
                      </VStack>
                    </>
                  )}

                  <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                    {partMedia &&
                      partMedia.map((media, index) => {
                        return (
                          <GridItem key={media.mediaId} colSpan={3}>
                            <HStack>
                              <MediaPreview src={media.filePath} filename={media.fileName} maxWidth="200px" />
                              {isAllowedToEdit() && <TrashIcon onClick={() => removeMedia(index)} />}
                            </HStack>
                          </GridItem>
                        );
                      })}

                    {(!partMedia || partMedia.length === 0) && <Text>No media</Text>}
                  </Grid>
                </Box>
              </SimpleGrid>

              <ButtonGroup pt={5} spacing={5}>
                {isAllowedToEdit() && (
                  <Button type="submit" colorScheme="gripgreen" bg="gripgreen" variant="solid">
                    Save
                  </Button>
                )}
                <ExitButtonWithConfirmation onConfirm={history.goBack} isDirty={dirty} />
                {isAllowedToEdit() && part?.partNumber && <DeleteConfirmationDialog handleDelete={deleteHandle} />}
              </ButtonGroup>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default PartForm;
