import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import PartForm from "../../../forms/PartForm";

const PartManagerFormCreatePage: React.FC = () => {
  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Parts Manager</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/part-manager/parts" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Parts
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Add Part</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <PartForm />
    </>
  );
};

export default PartManagerFormCreatePage;
