import React from "react";
import { Button } from "@chakra-ui/react";
import { mgr } from "../helpers/AuthService";
import { gripPortalURL } from "../helpers/Settings";

const UnauthoriziedPage: React.FC = () => {
  window.location.href = `${gripPortalURL}/vehicleprofiles`;

  function logout() {
    window.localStorage.setItem("user", "");
    mgr.signoutRedirect();
  }

  return (
    <>
      Not authorized to view this portal<br></br>
      <Button onClick={() => logout()}>Logout</Button>
    </>
  );
};

export default UnauthoriziedPage;
