import { Box, Button, Heading, useToast, Text, SimpleGrid, ButtonGroup } from "@chakra-ui/react";
import * as React from "react";
import TextField from "../components/TextField";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { GetVehicleDto, UpdateVehicleModel, CreateVehicleModel } from "../helpers/GripApi";
import useAuth from "../hooks/useAuth";
import DeleteConfirmationDialog from "../components/DeleteConfirmationDialog";
import { useHistory } from "react-router-dom";
import ExitButtonWithConfirmation from "../components/ExitButtonWithConfirmation";
import AmountField from "../components/AmountField";
import { FormatLengthError, FormatRequiredError } from "../helpers/ValidationErrors";

type VehicleFormProps = {
  vehicle?: GetVehicleDto;
};

const validationSchema = yup.object().shape({
  vin: yup.string().max(17, FormatLengthError("VIN", 17)),
  idleTrackerIMEI: yup.string().max(50, FormatLengthError("IMEI", 50)),
  owner: yup.string().max(500, FormatLengthError("Company Name", 500)),
  assetName: yup.string().max(50, FormatLengthError("Asset Name", 50)).required(FormatRequiredError("Asset Name")),
  vehicleGroup: yup.string().max(17, FormatLengthError("Vehicle Group", 50)),
  // year: yup.number(),
  make: yup.string().max(50, FormatLengthError("Make", 50)),
  model: yup.string().max(50, FormatLengthError("Model", 50)),
  series: yup.string().max(50, FormatLengthError("Series", 50)),
  bodyType: yup.string().max(50, FormatLengthError("Body Type", 50)),
  gvwrClass: yup.string().max(50, FormatLengthError("GVWR Class", 50)),
  vehicleType: yup.string().max(50, FormatLengthError("Vehicle Type", 50)),
  fuelType: yup.string().max(50, FormatLengthError("Fuel Type", 50)),
  // fuelCost: yup.number(),
  engineType: yup.string().max(50, FormatLengthError("Engine Type", 50)),
  engineManufacturer: yup.string().max(50, FormatLengthError("Engine Manufacturer", 50)),
  brakeSystem: yup.string().max(50, FormatLengthError("Brake System", 50)),
  driveLineType: yup.string().max(50, FormatLengthError("Drive Line Type", 50)),
  controller: yup.string().max(50, FormatLengthError("Controller", 50)),
  screen: yup.string().max(50, FormatLengthError("Screen", 50)),
  other: yup.string().max(50, FormatLengthError("Other", 50)),
  frequency: yup.string().max(50, FormatLengthError("Frequency", 50)),
});

const VehicleForm: React.FC<VehicleFormProps> = ({ vehicle }) => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const initialValues: GetVehicleDto = {
    vin: vehicle?.vin || "",
    idleTrackerIMEI: vehicle?.idleTrackerIMEI || "",
    owner: vehicle?.owner || "",
    assetName: vehicle?.assetName || "",
    vehicleGroup: vehicle?.vehicleGroup || "",
    year: vehicle?.year || 2000,
    make: vehicle?.make || "",
    model: vehicle?.model || "",
    series: vehicle?.series || "",
    bodyType: vehicle?.bodyType || "",
    gvwrClass: vehicle?.gvwrClass || "",
    vehicleType: vehicle?.vehicleType || "",
    fuelType: vehicle?.fuelType || "",
    fuelCost: vehicle?.fuelCost || undefined,
    engineType: vehicle?.engineType || "",
    engineManufacturer: vehicle?.engineManufacturer || "",
    brakeSystem: vehicle?.brakeSystem || "",
    driveLineType: vehicle?.driveLineType || "",
    controller: vehicle?.controller || "",
    screen: vehicle?.screen || "",
    other: vehicle?.other || "",
    frequency: vehicle?.frequency || "",
  };

  const deleteHandle = () => {
    if (vehicle?.vehicleId) {
      auth.client
        ?.vehicle_Delete(vehicle?.vehicleId, "1.0")
        .then(() => {
          toast({
            position: "bottom",
            status: "success",
            title: "Vehicle deleted",
          });
          history.goBack();
        })
        .catch((e) => handleError(e.errors?.Error[0]));
    }
  };

  const handleResponse = (vehicle: GetVehicleDto | null, title: string) => {
    toast({
      position: "bottom",
      status: "success",
      title: title,
    });
  };
  const handleError = (e: Error, title?: string) => {
    if (title) {
      toast({
        position: "bottom",
        status: "error",
        title: title,
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        if (vehicle?.vehicleId) {
          const existingVehicle = values as UpdateVehicleModel;
          existingVehicle.vehicleId = vehicle?.vehicleId;
          auth.client
            ?.vehicle_Update(existingVehicle.vehicleId, "1.0", existingVehicle)
            .then(() => {
              handleResponse(null, "Vehicle updated");
              resetForm({});
              history.goBack();
            })
            .catch((e) => handleError(e.errors?.Error[0]));
        } else {
          auth.client
            ?.vehicle_Create("1.0", values as CreateVehicleModel)
            .then(() => {
              handleResponse(null, "Vehicle added");
              resetForm({});
              history.goBack();
            })
            .catch((e) => handleError(e.errors));
        }
      }}
    >
      {({ values, dirty }) => (
        <>
          <Box p="6" rounded="lg" backgroundColor="background">
            <Form noValidate>
              <Heading size="lg" mt={2} mb={6}>
                {vehicle?.assetName ? "Edit Vehicle" : "Add Vehicle"}
              </Heading>

              <Text fontSize="xl" pb={5} fontWeight="bold">
                Vehicle Details
              </Text>
              <SimpleGrid spacing={5} minChildWidth="500px">
                <TextField title="VIN" name="vin" maxLength={17} isRequired={false} />
                <TextField title="Idle Tracker IMEI" name="idleTrackerIMEI" />
                <TextField title="Company Name" name="owner" />
                <TextField title="Asset Name" name="assetName" maxLength={50} isRequired />
                <TextField title="Vehicle Group" name="vehicleGroup" />
                <AmountField placeholder="Year" name="year" isPrice={false} />
                <TextField title="Make" name="make" />
                <TextField title="Model" name="model" />
                <TextField title="Series" name="series" />
                <TextField title="Body Type" name="bodyType" />
                <TextField title="GVWR Class" name="gvwrClass" />
                <TextField title="Vehicle Type" name="vehicleType" />
                <TextField title="Fuel Type" name="fuelType" />
                <AmountField placeholder="Fuel Cost" name="fuelCost" />
                <TextField title="Engine Type" name="engineType" />
                <TextField title="Engine Manufacturer" name="engineManufacturer" />
                <TextField title="Brake System" name="brakeSystem" />
                <TextField title="Drive Line Type" name="driveLineType" />
                <TextField title="Controller" name="controller" />
                <TextField title="Screen" name="screen" />
                <TextField title="Other" name="other" />
                <TextField title="Frequency" name="frequency" />
              </SimpleGrid>

              <ButtonGroup pt={10} spacing={5}>
                <Button type="submit" variant="solid" colorScheme="gripgreen" bg="gripgreen">
                  Save
                </Button>
                <ExitButtonWithConfirmation onConfirm={history.goBack} isDirty={dirty} />
                {vehicle?.assetName && <DeleteConfirmationDialog handleDelete={deleteHandle} />}
              </ButtonGroup>
            </Form>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default VehicleForm;
