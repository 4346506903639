import { Box, FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import * as React from "react";
import { GetClientDto, GetPartCategoryDto, GetPartDto } from "../helpers/GripApi";

interface OptionLabel {
  label: string;
  value: number | string;
}

type SelectFieldProps = {
  placeholder: string;
  name: string;
  error?: string | undefined;
  isRequired?: boolean;
  partStatus?: OptionLabel[] | undefined;
  category?: GetPartCategoryDto[];
  media?: OptionLabel[] | undefined;
  parts?: GetPartDto[];
  clients?: GetClientDto[];
};

const SelectField: React.FC<SelectFieldProps> = ({ placeholder, name, isRequired = true, partStatus, category, media, parts, clients }) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl id={name} isRequired={isRequired} isInvalid={(meta.error && meta.touched) || false}>
          <FormLabel>{placeholder}</FormLabel>
          <Box display="flex" justifyContent="space-between" m="auto" alignItems="center">
            <Select placeholder="Select option" {...field} name={name}>
              {partStatus
                ? partStatus.map((d, i) => <option value={d.value}>{d.label || ""}</option>)
                : category
                ? category.map((d, i) => <option value={d.partCategoryId || ""}>{d.name || ""}</option>)
                : media
                ? media.map((d, i) => <option value={d.value || ""}>{d.label || ""}</option>)
                : parts
                ? parts.map((d, i) => <option value={d.partNumber || ""}>{d.partNumber || ""}</option>)
                : clients
                ? clients.map((d, i) => <option value={d.clientId || ""}>{d.company || ""}</option>)
                : null}
            </Select>
          </Box>
          {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};

export default SelectField;
