import * as React from "react";
import { Box, Drawer, DrawerOverlay, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerContent, Stack, Text } from "@chakra-ui/react";

import SidebarItem from "./SidebarItem";

import { isSuperAdmin } from "../../helpers/AuthService";

import DashboardIcon from "mdi-react/ViewDashboardIcon";
import ClientManagerIcon from "mdi-react/AccountMultipleIcon";
import KitManagerIcon from "mdi-react/HammerWrenchIcon";
import PartManagerIcon from "mdi-react/WrenchIcon";
import MediaManagerIcon from "mdi-react/FolderMultipleImageIcon";
import ResourceManagerIcon from "mdi-react/FilesIcon";
import AccessManagerIcon from "mdi-react/BadgeAccountHorizontalIcon";
import ClientPortalIcon from "mdi-react/Car3PlusIcon";

interface SidebarProps {
  onClose: Function;
  isOpen: boolean;
  variant: string | undefined;
}

const SidebarContent = ({ onClick }: { onClick: Function }) => (
  <Stack className="sideMenu">
    <Box height="63px" backgroundColor="gripgreen" paddingLeft="16px" paddingTop="8px" borderRight="1px solid #727272">
      <Text fontSize="13" color="#FFFFFF">
        EPEQ® IM
      </Text>
      <Text fontSize="13" color="#FFFFFFB3">
        Idle Management
      </Text>
      <Text fontSize="13" color="#FFFFFFB3">
        <a href="https://epeqidlemanagement.com" style={{ color: "#FFFFFFB3" }}>
          epeqidlemanagement.com
        </a>
      </Text>
    </Box>

    <SidebarItem title="Dashboard" path="/dashboard" icon={<DashboardIcon size={24} color="#708c24" />} />

    <SidebarItem title="Client Manager" path="/client-manager" icon={<ClientManagerIcon size={24} color="#708c24" />} />

    <SidebarItem title="Kit Manager" path="/kit-manager" icon={<KitManagerIcon size={24} color="#708c24" />} />

    <SidebarItem
      title="Part Manager"
      icon={<PartManagerIcon size={24} color="#708c24" />}
      children={[
        {
          path: "/part-manager/parts",
          title: "Parts",
        },
        {
          path: "/part-manager/product-codes",
          title: "Product Codes",
        },
      ]}
    />

    <SidebarItem title="Media Manager" path="/media-manager" icon={<MediaManagerIcon size={24} color="#708c24" />} />

    <SidebarItem title="Resource Manager" path="/resource-manager" icon={<ResourceManagerIcon size={24} color="#708c24" />} />

    {isSuperAdmin() && (
      <SidebarItem title="Access Manager" path="/access-manager/grip-admin" icon={<AccessManagerIcon size={24} color="#708c24" />} />
    )}

    <SidebarItem title="Client Portal" path="/vehicle-manager" icon={<ClientPortalIcon size={24} color="#708c24" />} />
  </Stack>
);

export const Sidebar: React.FC<SidebarProps> = (props) => {
  return props.variant === "sidebar" ? (
    <Box position="fixed" left={0} w="256px" top={0} h="100%" bg="white" overflowY="auto">
      <SidebarContent onClick={() => props.onClose()} />
    </Box>
  ) : (
    <Drawer isOpen={props.isOpen} placement="left" colorScheme="gripgreen" onClose={() => props.onClose()}>
      <DrawerOverlay>
        <DrawerContent bg="white">
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <SidebarContent onClick={() => props.onClose()} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default Sidebar;
