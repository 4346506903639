import { FormControl, FormErrorMessage, FormLabel, Textarea } from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import * as React from "react";

type TextAreaProps = {
  placeholder: string;
  name: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  maxLength?: 524288 | number;
  rows?: number | undefined;
};

const TextArea: React.FC<TextAreaProps> = ({ placeholder, name, maxLength, isRequired = true, isReadOnly = false, rows = 5 }) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl id={name} isRequired={isRequired} isInvalid={(meta.error && meta.touched) || false}>
          <FormLabel>{placeholder}</FormLabel>
          <Textarea {...field} maxLength={maxLength} placeholder={placeholder} rows={rows} isReadOnly={isReadOnly} />
          {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};

export default TextArea;
