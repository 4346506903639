import React, { FunctionComponent, useState } from "react";
import { Heading, Box } from "@chakra-ui/layout";
import { Text, Breadcrumb, BreadcrumbItem, SimpleGrid, useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { GetClientDto, GetKitDto, GetPartCategoryDto, GetPartDto, GetVehicleDto } from "../../helpers/GripApi";
import Table from "../../components/Table";
import useAuth from "../../hooks/useAuth";
import { gripPortalURL } from "../../helpers/Settings";

const DashboardPage: FunctionComponent = () => {
  const auth = useAuth();
  const history = useHistory();
  const toast = useToast();
  const [users, setUsers] = useState<GetClientDto[]>([]);
  const [kits, setKits] = useState<GetKitDto[]>([]);
  const [parts, setParts] = useState<GetPartDto[]>([]);
  const [vehicles, setVehicles] = useState<GetVehicleDto[]>([]);
  const [partCategories, setPartCategories] = useState<GetPartCategoryDto[]>([]);

  const clientColumns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email address",
        accessor: "emailAddress",
      },
      {
        Header: "Business Phone Number",
        accessor: "businessPhoneNumber",
      },
      {
        Header: "Cell Phone",
        accessor: "cellPhoneNumber",
      },
    ],
    []
  );
  const clientRowClick = (row: GetClientDto) => {
    history.push({ pathname: "/client-manager/" + row.clientId });
  };

  const kitColumns = React.useMemo(
    () => [
      {
        Header: "Make",
        accessor: "make",
      },
      {
        Header: "Model",
        accessor: "model",
      },
      {
        Header: "Series",
        accessor: "series",
      },
      {
        Header: "Year",
        accessor: "year",
      },
    ],
    []
  );
  const kitRowClick = (row: GetKitDto) => {
    history.push({ pathname: "/kit-manager/" + row.kitId });
  };

  const partColumns = React.useMemo(
    () => [
      {
        Header: "Part Number",
        accessor: "partNumber",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Product Code",
        accessor: "partCategoryId",
        Cell({ cell: { value } }: { cell: { value: number } }) {
          return partCategories?.find((x) => x.partCategoryId === value)?.name ?? value;
        },
      },
    ],
    [partCategories]
  );
  const partRowClick = (row: GetPartDto) => {
    history.push("/part-manager/parts/" + row.partNumber);
  };

  const vehicleColumns = React.useMemo(
    () => [
      {
        Header: "Asset Name",
        accessor: "assetName",
      },
      {
        Header: "VIN",
        accessor: "vin",
      },
      {
        Header: "Company Name",
        accessor: "owner",
      },
      {
        Header: "Grip Version",
        accessor: "gripVersion",
      },
      {
        Header: "Idle Tracker IMEI",
        accessor: "idleTrackerIMEI",
      },
      {
        Header: "Fuel Type",
        accessor: "fuelType",
      },
      {
        Header: "Litres per Hour",
        accessor: "litresPerHour",
      },
      {
        Header: "Fuel Cost",
        accessor: "fuelCost",
      },
    ],
    []
  );
  const vehicleRowClick = (row: GetVehicleDto) => {
    window.location.href = `${gripPortalURL}/addvehicle?id=${row.vehicleId}`;
  };

  React.useEffect(() => {
    const handleError = (title?: string) => {
      if (title) {
        toast({
          position: "bottom",
          status: "error",
          title: title,
        });
      }
    };
    auth.client
      ?.client_GetAll(5, "1.0")
      .then((response) => {
        setUsers(response.result);
      })
      .catch((e) => handleError(e.errors?.Error[0]));

    auth.client
      ?.kit_GetAll(5, "1.0")
      .then((response) => {
        setKits(response.result);
      })
      .catch((e) => handleError(e.errors?.Error[0]));

    auth.client
      ?.partCategory_GetAll("1.0")
      .then((response) => {
        setPartCategories(response.result);
      })
      .catch((e) => handleError(e.errors?.Error[0]));

    auth.client
      ?.part_GetAll(5, "1.0")
      .then((response) => {
        setParts(response.result);
      })
      .catch((e) => handleError(e.errors?.Error[0]));

    auth.client
      ?.vehicle_GetAll(5, "1.0")
      .then((response) => {
        setVehicles(response.result);
      })
      .catch((e) => handleError(e.errors?.Error[0]));
  }, [auth.client, toast]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Dashboard </Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <SimpleGrid columns={2} mb={10} spacing={10}>
        <Box
          bg="white"
          p={4}
          borderRadius="lg"
          backgroundColor="background"
          style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
        >
          <Heading size="md" mt={2} mb={6}>
            Recently modified clients
          </Heading>
          <Table simple onRowClicked={clientRowClick} data={users} columns={clientColumns} />
        </Box>
        <Box
          bg="white"
          p={4}
          borderRadius="lg"
          backgroundColor="background"
          style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
        >
          <Heading size="md" mt={2} mb={6}>
            Recently modified kits
          </Heading>
          <Table simple onRowClicked={kitRowClick} data={kits} columns={kitColumns} />
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={2} mb={10} spacing={10}>
        <Box
          bg="white"
          p={4}
          borderRadius="lg"
          backgroundColor="background"
          style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
        >
          <Heading size="md" mt={2} mb={6}>
            Recently modified parts
          </Heading>
          <Table simple onRowClicked={partRowClick} data={parts} columns={partColumns} />
        </Box>
        <Box
          bg="white"
          p={4}
          borderRadius="lg"
          backgroundColor="background"
          style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
        >
          <Heading size="md" mt={2} mb={6}>
            Recently modified vehicles
          </Heading>
          <Table simple onRowClicked={vehicleRowClick} data={vehicles} columns={vehicleColumns} />
        </Box>
      </SimpleGrid>
    </>
  );
};

export default DashboardPage;
