import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import VehicleForm from "../../forms/VehicleForm";

const VehicleFormCreatePage: React.FC = () => {
  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/vehicle-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Vehicle Manager
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Add Vehicle</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <VehicleForm />
    </>
  );
};

export default VehicleFormCreatePage;
