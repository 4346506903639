// React
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra UI
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
// Grip API
import useAuth from "../../hooks/useAuth";
import AnalysisManualForm from "../../forms/Resources/AnalysisManualForm";
// import IgnitionSheetForm from "../../forms/Resources/IgnitionSheetForm";
import KitInstructionsForm from "../../forms/Resources/KitInstructionsForm";
import ManualsForm from "../../forms/Resources/ManualsForm";
import ServiceBulletinForm from "../../forms/Resources/ServiceBulletinForm";
import SoftwareForm from "../../forms/Resources/SoftwareForm";
import TrainingDocuments from "../../forms/Resources/TrainingDocumentsForm";
import GripControllerApplicationForm from "../../forms/Resources/GripControllerApplicationsForm";
import TrainingDocumentForm from "../../forms/Resources/TrainingDocumentsForm";

const ResourceCreatePage: React.FC = () => {
  const auth = useAuth();
  const [param, setParam] = useState<string>();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("type") ?? "";
    setParam(myParam.toLowerCase());
  }, [auth.client]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/resource-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Resource Manager
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Create Resource</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      {param === "analysismanual" && <AnalysisManualForm />}
      {/* {param === "ignitionsheet" && <IgnitionSheetForm />} */}
      {param === "kitinstructions" && <KitInstructionsForm />}
      {param === "manual" && <ManualsForm />}
      {param === "servicebulletin" && <ServiceBulletinForm />}
      {param === "software" && <SoftwareForm />}
      {param === "gripcontrollerapplication" && <GripControllerApplicationForm />}
      {param === "trainingdocuments" && <TrainingDocumentForm />}
    </>
  );
};

export default ResourceCreatePage;
