import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import * as React from "react";

type AmountFieldProps = {
  placeholder: string;
  name: string;
  isRequired?: boolean;
  isPrice?: boolean | undefined;
};

const AmountField: React.FC<AmountFieldProps> = ({
  placeholder,
  isRequired,
  name,
  isPrice = true,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl
          id={name}
          isRequired={isRequired}
          isInvalid={(meta.error && meta.touched) || false}
        >
          <FormLabel>{placeholder}</FormLabel>

          <InputGroup>
            {isPrice && <InputLeftAddon children="$" />}

            <NumberInput precision={2} step={0.2} value={field.value} w="100%">
              {isPrice ? (
                <NumberInputField
                  borderTopLeftRadius="0"
                  borderBottomLeftRadius="0"
                  placeholder="Enter amount"
                  {...field}
                  name={name}
                />
              ) : (
                <NumberInputField
                  placeholder="Enter amount"
                  {...field}
                  name={name}
                />
              )}
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
          </InputGroup>
        </FormControl>
      )}
    </Field>
  );
};

export default AmountField;
