import React from "react";
import { useParams } from "react-router";
import { GetVehicleDto } from "../../helpers/GripApi";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, useToast } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import VehicleForm from "../../forms/VehicleForm";

const VehicleFormEditPage: React.FC = () => {
  const params: any = useParams();
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();
  const [vehicle, setVehicle] = React.useState<GetVehicleDto>();

  const vehicleId = params.vehicleId;

  React.useEffect(() => {
    if (vehicleId) {
      auth.client
        ?.vehicle_Get(vehicleId, "1.0")
        .then((r) => {
          setVehicle(r.result);
        })
        .catch((e) => {
          toast({
            position: "bottom",
            status: "error",
            title: "There was an error getting the vehicle",
          });
          history.goBack();
        });
    } else {
      history.goBack();
    }
  }, [auth.client, history, toast, vehicleId]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/vehicle-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Vehicle Manager
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Edit Vehicle</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <VehicleForm vehicle={vehicle} />
    </>
  );
};

export default VehicleFormEditPage;
