import React from "react";
import PartForm from "../../../forms/PartForm";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { GetPartDto } from "../../../helpers/GripApi";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  useToast,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const PartFormEditPage: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const params: any = useParams();
  const toast = useToast();

  const part_number = params.part_number;

  const [part, setPart] = React.useState<GetPartDto>();

  React.useEffect(() => {
    if (part_number) {
      auth.client
        ?.part_Get(part_number, "1.0")
        .then((r) => {
          setPart(r.result);
        })
        .catch((e) => {
          toast({
            position: "bottom",
            status: "error",
            title: "There was an error getting this part.",
          });
          history.goBack();
        });
    } else {
      history.goBack();
    }
  }, [auth.client, history, part_number, toast]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Parts Manager</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={NavLink}
            to="/part-manager/parts"
            activeClassName="activeBreadcrumbLink"
            textColor="gripgreen"
            exact
          >
            Parts
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Edit Part</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      {part && <PartForm part={part} />}
    </>
  );
};

export default PartFormEditPage;
