import Oidc, { User } from "oidc-client";
import jwt_decode from "jwt-decode";

import { identityServerURL, localURL } from "./Settings";

const config = {
  authority: `${identityServerURL}`,
  client_id: "gripidle.administration.portal",
  redirect_uri: `${localURL}/callback`,
  response_type: "code",
  scope: "openid profile gripidle.api IdentityServerApi",
  post_logout_redirect_uri: `${localURL}/index.html`,
};
export const mgr = new Oidc.UserManager(config);

export function setUser(user: User) {
  window.localStorage.setItem("user", user.access_token);
  window.localStorage.setItem("name", user.profile.name ?? "");
}

export function getUserAccessToken(): string | null {
  return window.localStorage.getItem("user");
}

export function isUserRoleAuthorized(): boolean {
  let user = getUserAccessToken();
  if (user) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const token: any = jwt_decode(user);
    if (token.role === "gripidle.superuser" || token.role === "gripidle.adminportaluser" || token.role === "gripidle.adminportalviewuser") {
      return true;
    }
    return false;
  }
  return false;
}

export function isAllowedToEdit(): boolean {
  let user = getUserAccessToken();
  if (user) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const token: any = jwt_decode(user);
    if (token.role === "gripidle.superuser" || token.role === "gripidle.adminportaluser") {
      return true;
    }
    return false;
  }
  return false;
}

export function isSuperAdmin(): boolean {
  let user = getUserAccessToken();
  if (user) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const token: any = jwt_decode(user);
    if (token.role === "gripidle.superuser") {
      return true;
    }
    return false;
  }
  return false;
}

export function getUsername(): string {
  let user = getUserAccessToken();
  if (user) {
    return window.localStorage.getItem("name") ?? "";
  }
  return "";
}
