// React
import * as React from "react";
import { useHistory } from "react-router-dom";
import { CreateKitOptionModel, GetFuelTypeDto, GetIgnitionTypeDto, GetKitDto, GetPartDto, UpdateKitModel } from "../helpers/GripApi";
import useAuth from "../hooks/useAuth";
import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  useToast,
  Stack,
  Text,
  Select,
  FormLabel,
  Input,
  ButtonGroup,
  Flex,
  Spacer,
  List,
  ListItem,
  FormControl,
  Divider,
} from "@chakra-ui/react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import TextField from "../components/TextField";
import ExitButtonWithConfirmation from "../components/ExitButtonWithConfirmation";
import DeleteConfirmationDialog from "../components/DeleteConfirmationDialog";
import ManageFuelTypeModal from "../components/ManageFuelTypeModal";
import SelectField2, { SelectFieldOptionProps } from "../components/SelectField2";
import AmountField from "../components/AmountField";
import ManageIgnitionTypeModal from "../components/ManageIgnitionTypeModal";
import { FormatRequiredError } from "../helpers/ValidationErrors";

// Props
type KitFormProps = {
  kit?: GetKitDto;
};

// Yup Form Validation
const validationSchema = yup.object().shape({
  // Make
  make: yup.string().required("Make is required").max(50, "Max length 50 characers"),

  // Model
  model: yup.string().max(50, "Max length 50 characers"),

  // Series
  series: yup.string().max(50, "Max length 50 characers"),

  // Year
  year: yup.number().positive().integer().required("Year is required"), // ! validation doesn't update on view - NB

  // Vehicle Fuel Type
  fuelTypeId: yup.number().required(FormatRequiredError("Fuel Type")),

  // Vehicle Ignition Type
  ignitionTypeId: yup.number().required(FormatRequiredError("Ignition Type")),

  // Vehicle Controller Program
  controllerProgram: yup.string().required("Controller program is required").max(150, "Max length 150 characers"),

  // Vehicle Screen Program
  screenProgram: yup.string().required("Screen program is required").max(150, "Max length 150 characers"),

  // Vehicle Wiring Document
  wiringDocument: yup.string().required("Wiring document is required").max(150, "Max length 150 characers"),

  // Vehicle Hardware Kit
  hardwareKit: yup.string().required("Hardware kit is required").max(50, "Max length 150 characers"),
});

const KitForm: React.FC<KitFormProps> = ({ kit }) => {
  const history = useHistory();
  const toast = useToast();
  const auth = useAuth();

  const [parts, setParts] = React.useState<GetPartDto[]>([]);
  const [fuelTypes, setFuelTypes] = React.useState<GetFuelTypeDto[]>([]);
  const [ignitionTypes, setIgnitionTypes] = React.useState<GetIgnitionTypeDto[]>([]);
  const [selectedBrakeStart, setSelectedBrakeStart] = React.useState<string>();
  const [selectedSeatBeltStart, setSeatBeltStartSelected] = React.useState<string>();
  const [selectedEngineStartHydraulic, setEngineStartHydraulicSelected] = React.useState<string>();
  const [selectedAirPressureMonitoring, setAirPressureMonitoringSelected] = React.useState<string>();

  const [selectedDoorOnCan, setDoorOnCanSelected] = React.useState<string>();
  const [selectedBunkHeater, setBunkHeaterSelected] = React.useState<string>();
  const [selectedBunkAC, setBunkACSelected] = React.useState<string>();
  const [selectedParkBrake, setParkBrakeSelected] = React.useState<string>();
  const [selectedHydraulicBrake, setHydraulicBrakeSelected] = React.useState<string>();
  const [selectedElectricBrake, setElectricBrakeSelected] = React.useState<string>();

  const [kitOptions, setKitOptions] = React.useState<any[]>([]);
  const [selectedPartNumber, setSelectedPartNumber] = React.useState<string>();
  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState<number | undefined>();

  React.useEffect(() => {
    loadFuelTypes();
    loadIgnitionTypes();

    auth.client?.part_GetAll(undefined, "1.0").then((response) => {
      setParts(response.result);
    });

    let options: CreateKitOptionModel[] = [];
    kit?.kitOptions?.forEach((kitOption) => {
      const option: any = {
        optionID: kitOption.kitOptionId,
        heading: kitOption.heading,
        option: kitOption.option,
        partIds: [],
      };
      kitOption.parts?.forEach((part) => {
        option.partIds.push(part.partNumber!);
      });
      options.push(option);
    });

    setSelectedBrakeStart(kit?.brakeStart ? "Yes" : "No");
    setSeatBeltStartSelected(kit?.seatBeltStart ? "Yes" : "No");
    setEngineStartHydraulicSelected(kit?.engineStartHydraulic ? "Yes" : "No");
    setAirPressureMonitoringSelected(kit?.airPressureMonitoring ? "Yes" : "No");
    setDoorOnCanSelected(kit?.doorOnCan ? "Yes" : "No");
    setBunkHeaterSelected(kit?.bunkHeater ? "Yes" : "No");
    setBunkACSelected(kit?.bunkAC ? "Yes" : "No");
    setParkBrakeSelected(kit?.parkBrake ? "Yes" : "No");
    setHydraulicBrakeSelected(kit?.hydraulicBrake ? "Yes" : "No");
    setElectricBrakeSelected(kit?.electricBrake ? "Yes" : "No");

    setKitOptions(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kit, auth.client]);

  const initialFormValues: any = {
    make: kit?.make || "",
    model: kit?.model || "",
    series: kit?.series || "",
    year: kit?.year || 2000,
    fuelTypeId: kit?.fuelTypeId || "",
    ignitionTypeId: kit?.ignitionTypeId || "",
    controllerProgram: kit?.controllerProgram || "",
    screenProgram: kit?.screenProgram || "",
    wiringDocument: kit?.wiringDocument || "",
    hardwareKit: kit?.hardwareKit || "",
    brakeStart: kit?.brakeStart ? "Yes" : "No",
    seatBeltStart: kit?.seatBeltStart ? "Yes" : "No",
    engineStartHydraulic: kit?.engineStartHydraulic ? "Yes" : "No",
    airPressureMonitoring: kit?.airPressureMonitoring ? "Yes" : "No",
    doorOnCan: kit?.doorOnCan ? "Yes" : "No",
    bunkHeater: kit?.bunkHeater ? "Yes" : "No",
    bunkAC: kit?.bunkAC ? "Yes" : "No",
    parkBrake: kit?.parkBrake ? "Yes" : "No",
    hydraulicBrake: kit?.hydraulicBrake ? "Yes" : "No",
    electricBrake: kit?.electricBrake ? "Yes" : "No",
  };

  const cancel = () => {
    history.goBack();
  };

  const handleError = (title: string) => {
    toast({
      position: "bottom",
      status: "error",
      title: title,
    });
  };

  const loadFuelTypes = () => {
    auth.client?.fuelType_GetAll(undefined, "1.0").then((response) => {
      if (response.status === 200) {
        setFuelTypes(response.result.filter((e) => e.fuelTypeId === initialFormValues.fuelTypeId));
      }
    });
  };

  const loadIgnitionTypes = () => {
    auth.client?.ignitionType_GetAll(undefined, "1.0").then((response) => {
      if (response.status === 200) {
        setIgnitionTypes(response.result.filter((e) => e.ignitionTypeId === initialFormValues.ignitionTypeId));
      }
    });
  };

  return (
    <Box p="6" rounded="lg" backgroundColor="background">
      <Heading size="lg" mt={2} mb={6}>
        {kit?.kitId ? "View Kit" : "Add Kit"}
      </Heading>

      <SimpleGrid columns={2} spacing={10}>
        <Formik enableReinitialize initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={(values, { resetForm }) => {}}>
          {({ values, dirty }) => (
            <>
              <Form noValidate>
                {/* start:: Form Content */}
                {/* start:: Information */}

                <Text fontSize="xl" pb={5} fontWeight="bold">
                  Information
                </Text>
                <SimpleGrid columns={2} spacing={5}>
                  <TextField title="Make" name="make" isReadOnly />
                  <TextField title="Model" name="model" isReadOnly />
                  <TextField title="Series" name="series" isReadOnly />
                  <TextField title="Year" name="year" isReadOnly />
                </SimpleGrid>
                {/* end:: Information */}

                {/* start:: Vehicle Details */}

                <Box>
                  <Text fontSize="xl" pb={5} pt={10} fontWeight="bold">
                    {initialFormValues.fueldTypeId}
                  </Text>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Fuel Type</FormLabel>
                        <Input readOnly value={fuelTypes.length > 0 ? fuelTypes[0].name : ""} />
                      </FormControl>
                    </Box>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Ignition Type</FormLabel>
                        <Input readOnly value={ignitionTypes.length > 0 ? ignitionTypes[0].name : ""} />
                      </FormControl>
                    </Box>
                  </SimpleGrid>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <TextField title="Controller program" name="controllerProgram" isReadOnly />
                    </Box>
                    <Box pb={5}>
                      <TextField title="Screen program" name="screenProgram" isReadOnly />
                    </Box>
                  </SimpleGrid>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <TextField title="Ignition Detail Sheet" name="wiringDocument" isReadOnly />
                    </Box>
                    <Box pb={5}>
                      <TextField title="Hardware kit" name="hardwareKit" isReadOnly />
                    </Box>
                  </SimpleGrid>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Brake Start</FormLabel>
                        <Input value={selectedBrakeStart} readOnly />
                      </FormControl>
                    </Box>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Seat Belt Start</FormLabel>
                        <Input value={selectedSeatBeltStart} readOnly />
                      </FormControl>
                    </Box>
                  </SimpleGrid>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Engine Start for Hydraulic Temp</FormLabel>
                        <Input value={selectedEngineStartHydraulic} readOnly />
                      </FormControl>
                    </Box>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Air Pressure Monitoring</FormLabel>
                        <Input value={selectedAirPressureMonitoring} readOnly />
                      </FormControl>
                    </Box>
                  </SimpleGrid>

                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Door on CAN</FormLabel>
                        <Input value={selectedDoorOnCan} readOnly />
                      </FormControl>
                    </Box>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Bunk Heater Equipped</FormLabel>
                        <Input value={selectedBunkHeater} readOnly />
                      </FormControl>
                    </Box>
                  </SimpleGrid>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Bunk A/C Equipped</FormLabel>
                        <Input value={selectedBunkAC} readOnly />
                      </FormControl>
                    </Box>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Park Brake on CAN</FormLabel>
                        <Input value={selectedParkBrake} readOnly />
                      </FormControl>
                    </Box>
                  </SimpleGrid>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Hydraulic Brakes</FormLabel>
                        <Input value={selectedHydraulicBrake} readOnly />
                      </FormControl>
                    </Box>
                    <Box pb={5}>
                      <FormControl>
                        <FormLabel>Electric Parking brake</FormLabel>
                        <Input value={selectedElectricBrake} readOnly />
                      </FormControl>
                    </Box>
                  </SimpleGrid>
                </Box>

                {/* end:: Vehicle Details */}
                {/* end:: Form Content */}

                <ButtonGroup pt={10} spacing={5}>
                  <Button variant="solid" colorScheme="gripgreen" bg="gripgreen" onClick={cancel}>
                    Close
                  </Button>
                </ButtonGroup>
              </Form>
            </>
          )}
        </Formik>
        <Box>
          {/* start:: Kit Options Lists*/}
          <Flex w="100%" justifyItems="center" justifyContent="center" pb={5}>
            <Box>
              <Text fontSize="xl" fontWeight="bold">
                Kit Options
              </Text>
            </Box>
            <Spacer />
          </Flex>
          {kitOptions.map(function (kitOption: any, kitOptionIndex: number) {
            return (
              <Box key={kitOption.optionID} p="6" rounded="md" border="1px solid #CBD5E0" marginBottom={5}>
                <Box fontWeight="bold" fontSize="lg">
                  {kitOption.heading}
                </Box>
                <Box mb={4}>{kitOption.option}</Box>
                {kitOption.partIds.length > 0 && (
                  <>
                    <Box fontWeight="bold">Parts</Box>
                    <List spacing={5} mb={5}>
                      {kitOption.partIds.map(function (partId: string, partIdIndex: number) {
                        return <ListItem key={"part" + kitOption.optionID + partIdIndex.toString()}>{partId}</ListItem>;
                      })}
                    </List>
                  </>
                )}
              </Box>
            );
          })}
          {/* end:: Kit Options Lists */}
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default KitForm;
