// React
import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router";
import { GetMediaDto, FileParameter, UpdateMediaMetadataModel, SwaggerResponse } from "../helpers/GripApi";
import { FormatRequiredError } from "../helpers/ValidationErrors";
import Dropzone from "react-dropzone";

import useAuth from "../hooks/useAuth";

import * as yup from "yup";
import { Container, Box, Text, Heading, Button, useToast, Spinner, Stack, Center, ButtonGroup } from "@chakra-ui/react";

import TextArea from "../components/TextArea";
import TextField from "../components/TextField";
import DeleteConfirmationDialog from "../components/DeleteConfirmationDialog";
import ExitButtonWithConfirmation from "../components/ExitButtonWithConfirmation";
import MediaPreview from "../components/MediaPreview";

type MediaFormProps = {
  media?: GetMediaDto;
};

const validationSchema = yup.object().shape({
  name: yup.string().required(FormatRequiredError("Media Name")).max(150, "Max length 150 characers"),
});

const MediaForm: React.FC<MediaFormProps> = ({ media }) => {
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const [fileNames, setFileNames] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formFile, setFormFile] = React.useState<FileParameter>();

  const initialValues: GetMediaDto = {
    name: media?.name || "",
    description: media?.description || "",
  };

  /**
   * Handles the deleting of a Media entity.
   * @author Nick Brahimir
   */
  const handleDelete = () => {
    if (media?.mediaId) {
      auth.client
        ?.media_Delete(media.mediaId, "1.0")
        .then((response) => {
          if (response.status === 200) {
            handleSuccess("Media was successfully deleted.");
            history.push("/media-manager");
          }
        })
        .catch(() => handleError("There was an issue removing this media."))
        .finally(() => setLoading(false));
    }
  };

  /**
   * Handles the update event to update an existing media type with the API.
   * @param values The values that were submitted to the form by the user.
   */
  const handleUpdate = (values: GetMediaDto) => {
    if (media?.mediaId) {
      var updatePromise: Promise<SwaggerResponse<void>> | undefined;

      if (formFile) {
        updatePromise = auth.client?.media_Update(
          media.mediaId,
          "1.0",
          media.mediaId,
          values.name,
          values.description ?? "",
          formFile ?? ({} as FileParameter)
        );
      } else {
        const model = values as UpdateMediaMetadataModel;
        model.mediaId = media.mediaId;

        updatePromise = auth.client?.media_UpdateMetadata(media.mediaId, "1.0", model);
      }

      updatePromise
        ?.then((response) => {
          if (response.status === 204) {
            handleSuccess("Media was successfully updated.");
          }
        })
        .catch((error: Error) => handleError("There was an error updating the Media item."))
        .finally(() => setLoading(false));
    }
  };

  /**
   * Handles the create event to create a new Media object on the API.
   * @param values The values that were submitted to the form by the user.
   */
  const handleCreate = (values: GetMediaDto) => {
    auth.client
      ?.media_Create("1.0", values.name, values.description, formFile)
      .then((response) => {
        if (response.status === 201) {
          handleSuccess("Media was successfully uploaded.");
        }
      })
      .catch(() => handleError("There was an error creating the Media item."))
      .finally(() => setLoading(false));
  };

  /**
   * Handles the drop event when a user has dragged and dropped files onto the Dropzone.
   * @param acceptedFiles The files that were allowed to be uploaded from the Dropzone plugin.
   */
  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const fileParameter: FileParameter = { data: file, fileName: file.name };

    setFormFile(fileParameter);
    setFileNames(acceptedFiles.map((file) => file.name));
  };

  /**
   * Handle any errors that are returned from the API by showing the user a toast message at the bottom of the window.
   * @param error The error message passed from the API.
   */
  const handleError = (error: string) => {
    toast({ status: "error", title: error });
  };

  /**
   * Handle any success statuses from the API and show the user the message at the bottom of the window.
   * @param message The message to display to the user.
   */
  const handleSuccess = (message: string) => {
    toast({ status: "success", title: message });
    history.goBack();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLoading(true);

        if (media?.mediaId) {
          handleUpdate(values);
        } else {
          handleCreate(values);
        }
      }}
    >
      {({ dirty }) => (
        <Container maxW="container.md" marginStart="start">
          <Box p="6" rounded="lg" backgroundColor="background">
            <Form noValidate>
              <Stack spacing="15px" justify="space-between">
                <Heading size="lg" mt={2} mb={6}>
                  {media ? "Update Media" : "Create Media"}
                </Heading>

                {media && (
                  <Center>
                    <MediaPreview src={media.filePath} filename={media.fileNameOriginal} />
                  </Center>
                )}

                <Dropzone
                  multiple={false}
                  onDrop={handleDrop}
                  accept="image/gif, image/jpeg, image/png, image/svg+xml, image/webp, video/mp4, application/pdf, .zip, .exe"
                >
                  {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
                    const classStyle = isDragAccept ? "accept" : isDragReject ? "reject" : "";

                    return (
                      <div
                        {...getRootProps({
                          className: `dropzone ${classStyle}`,
                        })}
                      >
                        <Box w="100%" my={10} py={10}>
                          <input {...getInputProps()} />
                          <p>
                            <strong>To Replace the Existing File</strong>
                            <br /> Drag and Drop a File, or Click to Select a File
                          </p>
                        </Box>
                      </div>
                    );
                  }}
                </Dropzone>

                {fileNames.length > 0 && (
                  <>
                    <strong>File Name: {loading ? <Spinner /> : null}</strong>
                    {fileNames.map((fileName) => (
                      <Text key={fileName}>{fileName}</Text>
                    ))}
                  </>
                )}

                <TextField title="Media Name" name="name" isRequired />
                <TextArea placeholder="Description" name="description" isRequired={false} />

                <ButtonGroup pt={10} spacing={5}>
                  <Button type="submit" colorScheme="gripgreen" backgroundColor="gripgreen" isLoading={loading}>
                    Save
                  </Button>

                  {media && <DeleteConfirmationDialog handleDelete={handleDelete} />}

                  <ExitButtonWithConfirmation onConfirm={history.goBack} isDirty={dirty} />
                </ButtonGroup>
              </Stack>
            </Form>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default MediaForm;
