import React, { FunctionComponent, useState, useCallback } from "react";
import { Heading, Box } from "@chakra-ui/layout";
import { Button, Breadcrumb, BreadcrumbItem, Text, useToast, Flex, Spacer, ButtonGroup } from "@chakra-ui/react";

import Table from "../../../components/Table";
import useAuth from "../../../hooks/useAuth";
import { GetPartCategoryDto, GetPartDto } from "../../../helpers/GripApi";
import { isAllowedToEdit } from "../../../helpers/AuthService";
import { useHistory } from "react-router";
import ImportFileDialog from "../../../components/ImportFileDialog";

const PartManagerPage: FunctionComponent = () => {
  const auth = useAuth();
  const history = useHistory();
  const toast = useToast();
  const [parts, setParts] = useState<
    {
      partNumber: string | undefined;
      partCategoryId: number | undefined;
      description: string | undefined;
      distributorCAD: string;
      distributorUSD: string;
      installerCAD: string;
      installerUSD: string;
      msrpCAD: string;
      msrpUSD: string;
    }[]
  >([]);
  const [partCategories, setPartCategories] = useState<GetPartCategoryDto[] | undefined>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "Part Number",
        accessor: "partNumber",
      },
      {
        Header: "Category",
        accessor: "partCategoryId",
        Cell({ cell: { value } }: { cell: { value: number } }) {
          return partCategories?.find((x) => x.partCategoryId === value)?.name ?? value;
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Distributor Price (CAD)",
        accessor: "distributorCAD",
      },
      {
        Header: "Distributor Price (USD)",
        accessor: "distributorUSD",
      },
      {
        Header: "Installer Price (CAD)",
        accessor: "installerCAD",
      },
      {
        Header: "Installer Price (USD)",
        accessor: "installerUSD",
      },
      {
        Header: "MSRP Price (CAD)",
        accessor: "msrpCAD",
      },
      {
        Header: "MSRP Price (USD)",
        accessor: "msrpUSD",
      },
      {
        Header: "Status",
        accessor: "partStatus",
        Cell({ cell: { value } }: { cell: { value: number } }) {
          return value === 0 ? "Draft" : "Active";
        },
      },
    ],
    [partCategories]
  );

  const onOpen = () => {
    history.push("/part-manager/parts/create");
  };

  const onCloseDialog = () => {
    loadParts();
    setOpenDialog(!openDialog);
  };

  const handleError = useCallback(
    (title?: string) => {
      if (title) {
        toast({
          position: "bottom",
          status: "error",
          title: title,
        });
      }
    },
    [toast]
  );

  const handleRowClick = (row: GetPartDto) => {
    history.push("/part-manager/parts/" + row.partNumber);
  };

  const exportParts = () => {
    auth.client
      ?.part_Export(undefined, "1.0")
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.result.fileName ?? "GRIP Parts - " + new Date().toString() + ".xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => handleError(e.errors?.Error[0]));
  };

  React.useEffect(() => {
    auth.client
      ?.partCategory_GetAll("1.0")
      .then((response) => {
        setPartCategories(response.result);
      })
      .catch((e) => handleError(e.errors?.Error[0]));
  }, [auth.client, handleError]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => loadParts(), [auth.client]);

  const loadParts = () => {
    auth.client
      ?.part_GetAll(undefined, "1.0")
      .then((response) => {
        setParts(
          response.result.map((p) => {
            return {
              partNumber: p.partNumber,
              partCategoryId: p.partCategoryId,
              description: p.description,
              distributorCAD: "$ " + p.distributorCAD,
              distributorUSD: "$ " + p.distributorUSD,
              installerCAD: "$ " + p.installerCAD,
              installerUSD: "$ " + p.installerUSD,
              msrpCAD: "$ " + p.msrpCAD,
              msrpUSD: "$ " + p.msrpUSD,
            };
          })
        );
      })
      .catch((e) => handleError(e.errors?.Error[0]));
  };

  return (
    <>
      <Flex w="100%">
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
          <BreadcrumbItem>
            <Text>Parts Manager</Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>Parts</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
        <ButtonGroup spacing={5}>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={onOpen}>
              Add Part
            </Button>
          )}
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={() => setOpenDialog(!openDialog)}>
              Import
            </Button>
          )}
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={exportParts}>
              Export
            </Button>
          )}
          <input type="file" id="file" style={{ display: "none" }} />
        </ButtonGroup>
      </Flex>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6}>
          All Parts
        </Heading>
        {parts && partCategories && <Table onRowClicked={handleRowClick} data={parts} columns={columns} />}
        <ImportFileDialog openDialog={openDialog} onCloseDialog={onCloseDialog} />
      </Box>
    </>
  );
};

export default PartManagerPage;
