import React, { FunctionComponent, useState } from "react";
import { Heading, Box } from "@chakra-ui/layout";
import { Button, Breadcrumb, BreadcrumbItem, Text, useToast, Flex, Spacer } from "@chakra-ui/react";
import Table from "../../../components/Table";
import useAuth from "../../../hooks/useAuth";
import { GetPartCategoryDto } from "../../../helpers/GripApi";
import { isAllowedToEdit } from "../../../helpers/AuthService";
import { useHistory } from "react-router";

const CategoriesPage: FunctionComponent = () => {
  const auth = useAuth();
  const history = useHistory();
  const toast = useToast();
  const [partCategories, setPartCategories] = useState<GetPartCategoryDto[]>([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    []
  );

  const onOpen = () => {
    history.push("/part-manager/product-codes/create");
  };

  const handleRowClick = (row: GetPartCategoryDto) => {
    if (isAllowedToEdit()) {
      history.push("/part-manager/product-codes/" + row.partCategoryId);
    }
  };

  React.useEffect(() => {
    const handleError = (title?: string) => {
      if (title) {
        toast({
          position: "bottom",
          status: "error",
          title: title,
        });
      }
    };

    auth.client
      ?.partCategory_GetAll("1.0")
      .then((response) => {
        setPartCategories(response.result);
      })
      .catch((e) => handleError(e.errors?.Error[0]));
  }, [auth.client, toast]);

  return (
    <>
      <Flex w="100%">
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
          <BreadcrumbItem>
            <Text>Parts Manager</Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>Product Codes</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
        {isAllowedToEdit() && (
          <Button colorScheme="gripgreen" bg="gripgreen" onClick={onOpen}>
            Add Product Code
          </Button>
        )}
      </Flex>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Heading size="md" mt={2} mb={6}>
          All Product Codes
        </Heading>
        <Table onRowClicked={handleRowClick} data={partCategories} columns={columns} />
      </Box>
    </>
  );
};

export default CategoriesPage;
