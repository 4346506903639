import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";

import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";

import theme from "./theme";

import { mgr, setUser } from "./helpers/AuthService";

mgr
  .signinCallback()
  .then((user) => {
    if (user) {
      setUser(user);

      ReactDOM.render(
        <React.StrictMode>
          <ChakraProvider theme={theme}>
            <SimpleReactLightbox>
              <App />
            </SimpleReactLightbox>
          </ChakraProvider>
        </React.StrictMode>,
        document.getElementById("root")
      );
    } else {
      mgr.signinRedirect();
    }
  })
  .catch(function () {
    mgr.signinRedirect();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
