import { AuthenticationDto, Client } from "../helpers/GripApi";

export type AuthState = {
  client: Client;
};

export type LoginStateChangePayload = {
  user: AuthenticationDto;
  client: Client;
};

export type AuthAction = { type: "ERROR_CLEAR_MESSAGE" };

const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
  }
};

export default AuthReducer;
