import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react";
import React from "react";

type ConfirmationDialogProps = {
  text?: string;
  isDirty: boolean;
  onConfirm: () => void;
};

const ExitButtonWithConfirmation: React.FC<ConfirmationDialogProps> = ({ text = "Cancel", isDirty, onConfirm }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);

  // See: https://chakra-ui.com/docs/overlay/alert-dialog#usage
  const cancelRef = React.useRef(null);

  return (
    <>
      <Button
        size="md"
        colorScheme="gripgreen"
        backgroundColor="gripgreen"
        onClick={() => {
          if (isDirty) {
            // If the form is dirty, ask the user to confirm.
            setIsOpen(true);
          } else {
            // Otherwise, just perform the onConfirm passed from the parent.
            onConfirm();
          }
        }}
      >
        {text}
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent marginTop="110px">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Cancel
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure that you want to {text.toLowerCase()}? Any unsaved changes will be lost and cannot be recovered.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Go Back
              </Button>
              <Button
                colorScheme="gripgreen"
                backgroundColor="gripgreen"
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ExitButtonWithConfirmation;
