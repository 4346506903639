import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import useAuth from "../hooks/useAuth";
import { FileParameter, PartImportConfirmationDto } from "../helpers/GripApi";

type ImportFileDialogProps = {
  openDialog: boolean;
  onCloseDialog: () => void;
};

const ImportFileDialog: React.FC<ImportFileDialogProps> = ({ openDialog, onCloseDialog }) => {
  const auth = useAuth();
  const [fileUpload, setFileUpload] = React.useState<FileParameter>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [hasStatus, setHasStatus] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<PartImportConfirmationDto>({});
  const [lastImport, setLastImport] = React.useState<string>();
  const cancelRef = React.useRef(null);

  const toast = useToast();

  const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    const file = acceptedFiles[0];
    const fileParameter: FileParameter = { data: file, fileName: file.name };

    setFileUpload(fileParameter);

    auth.client
      ?.part_Import(false, "1.0", fileParameter)
      .then((response) => {
        setStatus(response.result);
        setHasStatus(true);
      })
      .catch((e) => handleError(e.errors?.Error[0]));
  };

  const handleSubmitFile = () => {
    setLoading(true);
    auth.client
      ?.part_Import(true, "1.0", fileUpload)
      .then((response) => {
        setHasStatus(false);
      })
      .catch((e) => {
        if (e.status === 204) {
          handleResponse("File imported successfullly");
          setLoading(false);
          onCloseDialog();
        } else {
          handleError(e.errors?.Error[0]);
        }
      });
  };

  const handleResponse = (title: string) => {
    toast({
      position: "bottom",
      status: "success",
      title: title,
    });
  };
  const handleError = (e: Error, title?: string) => {
    if (title) {
      toast({
        position: "bottom",
        status: "error",
        title: title,
      });
    }
  };

  React.useEffect(() => {
    auth.client
      ?.importHistory_GetLastPartImport("1.0")
      .then((r) => {
        setLastImport(r.result);
      })
      .catch((e) => {});
  }, [auth.client, toast]);

  return (
    <>
      <Modal isOpen={openDialog} size="5xl" onClose={onCloseDialog} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="bold" mt={2} mb={6}>
            Import
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {lastImport && (
              <Text mb={5}>
                <strong>Last File Imported:</strong> {lastImport}
              </Text>
            )}
            {/* TODO: DROP-ZONE */}
            <Dropzone
              multiple={false}
              onDrop={handleDrop}
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,"
            >
              {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                const classStyle = isDragAccept ? "accept" : isDragReject ? "reject" : "";
                return (
                  <div {...getRootProps({ className: `dropzone ${classStyle}` })}>
                    <input {...getInputProps()} />
                    <Box my={5} py={5}>
                      <input {...getInputProps()} />
                      <p>Drag and drop a file, or click to select a file</p>
                    </Box>
                  </div>
                );
              }}
            </Dropzone>
            {hasStatus && (
              <>
                <Text fontSize="xl" pb={5} fontWeight="bold">
                  Pending Changes
                </Text>
                <SimpleGrid columns={1} spacing={10}>
                  <Box>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex="1" textAlign="left">
                              {Object.keys(status.constrainedParts ?? {}).length} Errors
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          {(status.constrainedParts ?? []).map((key, index) => (
                            <p key={index}>
                              {key}
                            </p>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={3} spacing={10}>
                  <Box>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex="1" textAlign="left">
                              {Object.keys(status.addedParts ?? {}).length} Parts to be added
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          {Object.keys(status.addedParts ?? {}).map((key, index) => (
                            <p key={index}>
                              {key} - {status.addedParts![key].toString() ?? ""}
                            </p>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                  <Box>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex="1" textAlign="left">
                              {Object.keys(status.modifiedParts ?? {}).length} Parts to be modified
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          {Object.keys(status.modifiedParts ?? {}).map((key, index) => (
                            <p key={index}>
                              {key} - {status.modifiedParts![key].toString() ?? ""}
                            </p>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                  <Box>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex="1" textAlign="left">
                              {Object.keys(status.deletedParts ?? {}).length} Parts to be deleted
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          {Object.keys(status.deletedParts ?? {}).map((key, index) => (
                            <p key={index}>
                              {key} - {status.deletedParts![key].toString() ?? ""}
                            </p>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                </SimpleGrid>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={5}>
              <Button
                colorScheme="gripgreen"
                bg="gripgreen"
                onClick={() => {
                  handleSubmitFile();
                  setFileUpload(undefined);
                  setHasStatus(false);
                  setStatus({});
                }}
                isLoading={loading}
                isDisabled={(status.constrainedParts ?? []).length > 0}
              >
                Import
              </Button>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setFileUpload(undefined);
                  setStatus({});
                  setHasStatus(false);
                  onCloseDialog();
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportFileDialog;
