import { FunctionComponent } from "react";
import { Box } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { mgr, setUser } from "../../helpers/AuthService";

const CallbackPage: FunctionComponent = () => {
  const history = useHistory();

  mgr
    .signinRedirectCallback()
    .then((user) => {
      setUser(user);
      history.push("/dashboard");
    })
    .catch(function (e) {
      history.push("/dashboard");
    });

  return <Box display="flex" flexDirection="row" height="100%" minHeight="100vh" bg="gripgreen"></Box>;
};

export default CallbackPage;
