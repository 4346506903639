/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent, ReactNode, useState } from "react";

import { Column, useTable, useGlobalFilter, useSortBy, usePagination, useAsyncDebounce, HeaderGroup, Row, Cell } from "react-table";

import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import DownIcon from "mdi-react/ChevronDownIcon";
import UpIcon from "mdi-react/ChevronUpIcon";
import SearchIcon from "mdi-react/MagnifyIcon";

const Table: FunctionComponent<{
  columns: Column[];
  data: any;
  filter?: ReactNode;
  action?: ReactNode;
  simple?: boolean;
  onRowClicked?: (data: any) => void;
}> = ({ columns, data, filter, action, simple = false, onRowClicked }) => {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const hoverColour = "#F0F0F0"; // useColorModeValue("gray.50", "gray.500");
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((v: any) => {
    setGlobalFilter(v || undefined);
  }, 200);

  return (
    <>
      {!simple && (
        <Flex backgroundColor="background">
          <InputGroup size="sm" variant="outline" w="30%">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              value={value || ""}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              placeholder="Search..."
            />
          </InputGroup>
          {filter}
          <Spacer />
          {action}
        </Flex>
      )}

      <Box overflowX="auto" backgroundColor="background">
        <ChakraTable {...getTableProps()} fontSize="sm" backgroundColor="background">
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <DownIcon aria-label="sorted descending" />
                        ) : (
                          <UpIcon aria-label="sorted ascending" />
                        )
                      ) : (
                        ""
                      )}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row: Row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  onClick={() => {
                    if (onRowClicked) {
                      onRowClicked(row.original);
                    }
                  }}
                  _hover={{ background: hoverColour }}
                >
                  {row.cells.map((cell: Cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
        {!simple && (
          <HStack className="pagination" justifyContent="flex-end">
            <Select
              w="8rem"
              size="sm"
              variant="outline"
              value={pageSize}
              defaultValue={10}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </Select>

            <Button size="sm" variant="outline" onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Button size="sm" variant="outline" onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </HStack>
        )}
      </Box>
    </>
  );
};

export default Table;
