import axios from "axios";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import "./App.css";
import AuthContextProvider from "./context/AuthContextProvider";
import { setupInterceptorsTo } from "./helpers/Interceptors";
import { isSuperAdmin } from "./helpers/AuthService";

import AuthenticatedRoute from "./routes/AuthenticatedRoute";

import DashboardPage from "./pages/Dashboard/DashboardPage";

import ClientManagerPage from "./pages/ClientManager/ClientManagerPage";
import ClientCreatePage from "./pages/ClientManager/ClientCreatePage";
import ClientEditPage from "./pages/ClientManager/ClientEditPage";

import AdminManagerPage from "./pages/AdminManager/AdminManagerPage";
import AdminCreatePage from "./pages/AdminManager/AdminCreatePage";
import AdminEditPage from "./pages/AdminManager/AdminEditPage";

import ClientAdminManagerPage from "./pages/AccessManager/ClientAdminManager/ClientAdminManagerPage";
import ClientAdminCreatePage from "./pages/AccessManager/ClientAdminManager/ClientAdminCreatePage";
import ClientAdminEditPage from "./pages/AccessManager/ClientAdminManager/ClientAdminEditPage";

import KitManagerPage from "./pages/KitManager/KitManagerPage";
import KitEditPage from "./pages/KitManager/KitEditPage";
import KitCreatePage from "./pages/KitManager/KitCreatePage";

import PartManagerPage from "./pages/PartManager/Parts/PartsPage";
import PartManagerFormCreatePage from "./pages/PartManager/Parts/PartFormCreatePage";
import PartFormEditPage from "./pages/PartManager/Parts/PartFormEditPage";

import CategoriesPage from "./pages/PartManager/Categories/CategoriesPage";
import CategoryFormCreatePage from "./pages/PartManager/Categories/CategoryFormCreatePage";
import CategoryFormEditPage from "./pages/PartManager/Categories/CategoryFormEditPage";

import VehicleManagerPage from "./pages/VehicleManager/VehicleManagerPage";
import VehicleFormEditPage from "./pages/VehicleManager/VehicleFormEditPage";
import VehicleFormCreatePage from "./pages/VehicleManager/VehicleFormCreatePage";

import MediaPage from "./pages/MediaManager/MediaPage";
import MediaCreatePage from "./pages/MediaManager/MediaCreatePage";
import MediaEditPage from "./pages/MediaManager/MediaEditPage";

import ResourcePage from "./pages/ResourceManager/ResourcesPage";
import ResourceEditPage from "./pages/ResourceManager/ResourceEditPage";
import ResourceCreatePage from "./pages/ResourceManager/ResourceCreatePage";

import CallbackPage from "./pages/Authentication/CallbackPage";
import { isUserRoleAuthorized } from "./helpers/AuthService";

import UnauthoriziedPage from "./pages/UnauthoriziedPage";

export default function App() {
  setupInterceptorsTo(axios);

  return (
    <AuthContextProvider>
      <BrowserRouter>
        {isUserRoleAuthorized() && (
          <Switch>
            <Redirect exact from="/" to="/dashboard" />

            <AuthenticatedRoute path="/part-manager/parts" exact component={PartManagerPage} />
            <AuthenticatedRoute path="/part-manager/parts/create" exact component={PartManagerFormCreatePage} />
            <AuthenticatedRoute path="/part-manager/parts/:part_number" exact component={PartFormEditPage} />
            <AuthenticatedRoute path="/part-manager/product-codes" exact component={CategoriesPage} />
            <AuthenticatedRoute path="/part-manager/product-codes/create" exact component={CategoryFormCreatePage} />
            <AuthenticatedRoute path="/part-manager/product-codes/:partCategoryId" exact component={CategoryFormEditPage} />
            <AuthenticatedRoute path="/kit-manager" exact component={KitManagerPage} />
            <AuthenticatedRoute path="/kit-manager/create" exact component={KitCreatePage} />
            <AuthenticatedRoute path="/kit-manager/:kitId" exact component={KitEditPage} />

            <AuthenticatedRoute path="/access-manager/client-admin" exact component={ClientAdminManagerPage} />
            <AuthenticatedRoute path="/access-manager/client-admin/create" exact component={ClientAdminCreatePage} />
            <AuthenticatedRoute path="/access-manager/client-admin/:userId" exact component={ClientAdminEditPage} />

            {isSuperAdmin() && <AuthenticatedRoute path="/access-manager/grip-admin" exact component={AdminManagerPage} />}
            {isSuperAdmin() && <AuthenticatedRoute path="/access-manager/grip-admin/create" exact component={AdminCreatePage} />}
            {isSuperAdmin() && <AuthenticatedRoute path="/access-manager/grip-admin/:userId" exact component={AdminEditPage} />}

            <AuthenticatedRoute path="/client-manager" exact component={ClientManagerPage} />
            <AuthenticatedRoute path="/client-manager/create" exact component={ClientCreatePage} />
            <AuthenticatedRoute path="/client-manager/:userId" exact component={ClientEditPage} />

            <AuthenticatedRoute path="/media-manager" exact component={MediaPage} />

            <AuthenticatedRoute path="/media-manager/create" exact component={MediaCreatePage} />

            <AuthenticatedRoute path="/media-manager/:id" exact component={MediaEditPage} />

            <AuthenticatedRoute path="/resource-manager" exact component={ResourcePage} />
            <AuthenticatedRoute path="/resource-manager/create" exact component={ResourceCreatePage} />
            <AuthenticatedRoute path="/resource-manager/:id" exact component={ResourceEditPage} />

            <AuthenticatedRoute path="/dashboard" exact component={DashboardPage} />

            <AuthenticatedRoute path="/vehicle-manager" exact component={VehicleManagerPage} />
            <AuthenticatedRoute path="/vehicle-manager/create" exact component={VehicleFormCreatePage} />
            <AuthenticatedRoute path="/vehicle-manager/:vehicleId" exact component={VehicleFormEditPage} />

            <Route path="/callback" exact component={CallbackPage} />

            <Route path="*">
              {/* TODO: Potentially a not found page? */}
              <Redirect from="/" to="/callback" />
            </Route>
          </Switch>
        )}
        {!isUserRoleAuthorized() && (
          <Switch>
            <Route path="*" component={UnauthoriziedPage}></Route>
          </Switch>
        )}
      </BrowserRouter>
    </AuthContextProvider>
  );
}
