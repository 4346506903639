import React from "react";
import { Client } from "../helpers/GripApi";

export type AuthContextProps = {
  client: Client | null;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = React.createContext<AuthContextProps>(undefined!);
export default AuthContext;
