// React
import React, { useState } from "react";

import { useHistory } from "react-router-dom";
// Grip API
import useAuth from "../../hooks/useAuth";
import {
  GetAnalysisManualDto,
  // GetIgnitionSheetDto,
  GetKitInstructionsDto,
  GetManualsDto,
  GetServiceBulletinDto,
  GetSoftwareDto,
  GetGripControllerApplicationDto,
  GetTrainingDocumentDto,
} from "../../helpers/GripApi";
import { isAllowedToEdit } from "../../helpers/AuthService";
// Chakra UI
import { Breadcrumb, BreadcrumbItem, Box, Heading, Button, Text, Flex, Spacer, IconButton } from "@chakra-ui/react";
import Table from "../../components/Table";
import MediaPreview from "../../components/MediaPreview";
import dayjs from "dayjs";

import DownIcon from "mdi-react/ChevronDownIcon";
import UpIcon from "mdi-react/ChevronUpIcon";

const ResourcePage: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const [analysisManual, setAnalysisManuals] = useState<GetAnalysisManualDto[]>([]);
  const [trainingDocument, setTrainingDocuments] = useState<GetTrainingDocumentDto[]>([]);
  // const [ignitionSheet, setIgnitionSheet] = useState<GetIgnitionSheetDto[]>([]);
  const [kitInstructions, setKitInstructions] = useState<GetKitInstructionsDto[]>([]);
  const [manuals, setManuals] = useState<GetManualsDto[]>([]);
  const [serviceBulletin, setServiceBulletin] = useState<GetServiceBulletinDto[]>([]);
  const [software, setSoftware] = useState<GetSoftwareDto[]>([]);
  const [gripControllerApplication, setGripControllerApplication] = useState<GetGripControllerApplicationDto[]>([]);

  const [idleAnalysisSectionOpen, setIdleAnalysisSectionOpen] = useState<boolean>();
  const [trainingDocumentSectionOpen, setTrainingDocumentSectionOpen] = useState<boolean>();
  // const [ignitionSheetSectionOpen, setIgnitionSheetSectionOpen] = useState<boolean>();
  const [kitInstallSectionOpen, setKitInstallSectionOpen] = useState<boolean>();
  const [manualsSectionOpen, setManualsSectionOpen] = useState<boolean>();
  const [bulletinsSectionOpen, setBulletinsSectionOpen] = useState<boolean>();
  const [softwareSectionOpen, setSoftwareSectionOpen] = useState<boolean>();
  const [gripControllerSectionOpen, setGripControllerSectionOpen] = useState<boolean>();

  const AnalysicColumns = React.useMemo(
    () => [
      {
        accessor: "logo",
        Cell: (props: any) => {
          return <MediaPreview src={props.row.original.logo.filePath} filename={props.row.original.logo.fileNameOriginal} maxWidth={200} />;
        },
      },
      {
        Header: "Product",
        accessor: "product",
      },
      {
        Header: "Revision",
        accessor: "version",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "File",
        accessor: "media.fileNameOriginal",
      },
    ],
    []
  );

  const TrainingColumns = React.useMemo(
    () => [
      {
        accessor: "logo",
        Cell: (props: any) => {
          return <MediaPreview src={props.row.original.logo.filePath} filename={props.row.original.logo.fileNameOriginal} maxWidth={200} />;
        },
      },
      {
        Header: "Product",
        accessor: "product",
      },
      {
        Header: "Revision",
        accessor: "rev",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "File",
        accessor: "media.fileNameOriginal",
      },
    ],
    []
  );

  // const IgnitionSheetColumns = React.useMemo(
  //   () => [
  //     {
  //       accessor: "logo",
  //       Cell: (props: any) => {
  //         return <MediaPreview src={props.row.original.logo.filePath} filename={props.row.original.logo.fileNameOriginal} maxWidth={200} />;
  //       },
  //     },
  //     {
  //       Header: "Vehicle",
  //       accessor: "kit.kitId",
  //       Cell: (props: any) => {
  //         return (
  //           <>
  //             {props.row.original.kit.make} {props.row.original.kit.model} {props.row.original.kit.year}{" "}
  //           </>
  //         );
  //       },
  //     },
  //     {
  //       Header: "Date Released",
  //       accessor: "dateReleased",
  //       Cell: (props: any) => {
  //         return <>{dayjs(props.row.original.dateReleased).format("YYYY/MM/DD")} </>;
  //       },
  //     },
  //     {
  //       Header: "Description",
  //       accessor: "description",
  //     },

  //     {
  //       Header: "File",
  //       accessor: "media.fileNameOriginal",
  //     },
  //   ],
  //   []
  // );

  const KitInstructionsColumns = React.useMemo(
    () => [
      {
        accessor: "logo",
        Cell: (props: any) => {
          return <MediaPreview src={props.row.original.logo.filePath} filename={props.row.original.logo.fileNameOriginal} maxWidth={200} />;
        },
      },
      {
        Header: "Part Number",
        accessor: "part.partNumber",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "File",
        accessor: "media.fileNameOriginal",
      },
    ],
    []
  );

  const ManualsColumns = React.useMemo(
    () => [
      {
        accessor: "logo",
        Cell: (props: any) => {
          return <MediaPreview src={props.row.original.logo.filePath} filename={props.row.original.logo.fileNameOriginal} maxWidth={200} />;
        },
      },
      {
        Header: "Product",
        accessor: "name",
      },
      {
        Header: "Part Number",
        accessor: "part.partNumber",
      },
      {
        Header: "Description",
        accessor: "description",
      },

      {
        Header: "File",
        accessor: "media.fileNameOriginal",
      },
    ],
    []
  );

  const ServiceBulletinsColumns = React.useMemo(
    () => [
      {
        accessor: "logo",
        Cell: (props: any) => {
          return <MediaPreview src={props.row.original.logo.filePath} filename={props.row.original.logo.fileNameOriginal} maxWidth={200} />;
        },
      },
      {
        Header: "Product",
        accessor: "name",
      },
      {
        Header: "Date Released",
        accessor: "dateReleased",
        Cell: (props: any) => {
          return <>{dayjs(props.row.original.dateReleased).format("YYYY/MM/DD")} </>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "File",
        accessor: "media.fileNameOriginal",
      },
    ],
    []
  );

  const SoftwareColumns = React.useMemo(
    () => [
      {
        accessor: "logo",
        Cell: (props: any) => {
          return <MediaPreview src={props.row.original.logo.filePath} filename={props.row.original.logo.fileNameOriginal} maxWidth={200} />;
        },
      },
      {
        Header: "Product",
        accessor: "product",
      },
      {
        Header: "Revision",
        accessor: "version",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "File(s)",
        Cell: (props: any) => {
          return (
            <>
              {props.row.original.file1.fileNameOriginal} <br />
              {props.row.original.file2?.fileNameOriginal} <br />
              {props.row.original.file3?.fileNameOriginal}
            </>
          );
        },
      },
    ],
    []
  );

  const GripControllerApplicationColumns = React.useMemo(
    () => [
      {
        accessor: "logo",
        Cell: (props: any) => {
          return <MediaPreview src={props.row.original.logo.filePath} filename={props.row.original.logo.fileNameOriginal} maxWidth={200} />;
        },
      },
      {
        Header: "Product",
        accessor: "product",
      },
      {
        Header: "Revision",
        accessor: "version",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "File(s)",
        Cell: (props: any) => {
          return (
            <>
              {props.row.original.file1.fileNameOriginal} <br />
              {props.row.original.file2?.fileNameOriginal} <br />
              {props.row.original.file3?.fileNameOriginal}
            </>
          );
        },
      },
    ],
    []
  );

  React.useEffect(() => {
    auth.client?.analysisManual_GetAll(undefined, "1.0").then((response) => {
      setAnalysisManuals(response.result);
    });

    auth.client?.trainingDocument_GetAll(undefined, "1.0").then((response) => {
      setTrainingDocuments(response.result);
    });
    // auth.client?.ignitionSheet_GetAll(undefined, "1.0").then((response) => {
    //   setIgnitionSheet(response.result);
    // });
    auth.client?.kitInstruction_GetAll(undefined, "1.0").then((response) => {
      setKitInstructions(response.result);
    });
    auth.client?.manual_GetAll(undefined, "1.0").then((response) => {
      setManuals(response.result);
    });
    auth.client?.serviceBulletin_GetAll(undefined, "1.0").then((response) => {
      setServiceBulletin(response.result);
    });
    auth.client?.software_GetAll(undefined, "1.0").then((response) => {
      setSoftware(response.result);
    });
    auth.client?.gripControllerApplication_GetAll(undefined, "1.0").then((response) => {
      setGripControllerApplication(response.result);
    });

    setIdleAnalysisSectionOpen(false);
    setTrainingDocumentSectionOpen(false);
    // setIgnitionSheetSectionOpen(false);
    setKitInstallSectionOpen(false);
    setManualsSectionOpen(false);
    setBulletinsSectionOpen(false);
    setSoftwareSectionOpen(false);
    setGripControllerSectionOpen(false);
  }, [auth.client]);

  const handleAnalysisCreate = () => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/create?type=analysisManual");
    }
  };

  const handleTrainingCreate = () => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/create?type=trainingDocuments");
    }
  };
  // const handleIgnitionSheetCreate = () => {
  //   history.push("/resource-manager/create?type=ignitionsheet");
  // };
  const handleKitInstructionsCreate = () => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/create?type=kitinstructions");
    }
  };
  const handleManualsCreate = () => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/create?type=manual");
    }
  };
  const handleServiceBulletinsCreate = () => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/create?type=serviceBulletin");
    }
  };
  const handleSoftwareCreate = () => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/create?type=software");
    }
  };
  const handleGripControllerApplicationCreate = () => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/create?type=gripcontrollerapplication");
    }
  };

  const handleAnalysisManualRowClick = (row: GetAnalysisManualDto) => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/" + row.id + "?type=analysismanual");
    }
  };

  const handleTrainingDocumentsRowClick = (row: GetTrainingDocumentDto) => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/" + row.id + "?type=trainingdocuments");
    }
  };
  // const handleIgnitionSheetRowClick = (row: GetIgnitionSheetDto) => {
  //   history.push("/resource-manager/" + row.id + "?type=ignitionsheet");
  // };
  const handleKitInstructionsRowClick = (row: GetKitInstructionsDto) => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/" + row.id + "?type=kitinstructions");
    }
  };
  const handleManualsRowClick = (row: GetManualsDto) => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/" + row.id + "?type=manual");
    }
  };
  const handleServiceBulletinRowClick = (row: GetServiceBulletinDto) => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/" + row.id + "?type=servicebulletin");
    }
  };
  const handleSoftwareRowClick = (row: GetSoftwareDto) => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/" + row.id + "?type=software");
    }
  };
  const handleGripControllerApplicationRowClick = (row: GetGripControllerApplicationDto) => {
    if (isAllowedToEdit()) {
      history.push("/resource-manager/" + row.id + "?type=gripcontrollerapplication");
    }
  };

  return (
    <>
      <Flex w="100%">
        <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
          <BreadcrumbItem>
            <Text>Resource Manager</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Spacer />
      </Flex>

      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Flex w="100%">
          <Heading size="md" mt={2} mb={6} mr={3}>
            Idle Analysis Manuals
          </Heading>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={handleAnalysisCreate}>
              Create
            </Button>
          )}
          <Spacer />
          {!idleAnalysisSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<DownIcon color="grey.100" />}
              aria-label="Open Section"
              onClick={() => setIdleAnalysisSectionOpen(true)}
            />
          )}
          {idleAnalysisSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<UpIcon color="grey.100" />}
              aria-label="Close Section"
              onClick={() => setIdleAnalysisSectionOpen(false)}
            />
          )}
        </Flex>
        {idleAnalysisSectionOpen && <Table data={analysisManual} onRowClicked={handleAnalysisManualRowClick} columns={AnalysicColumns} />}
      </Box>
      {/* <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Flex w="100%">
          <Heading size="md" mt={2} mb={6} mr={3}>
            Ignition Sheets
          </Heading>
          <Button colorScheme="gripgreen" bg="gripgreen" onClick={handleIgnitionSheetCreate}>
            Create
          </Button>
          <Spacer />
          {!ignitionSheetSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<DownIcon color="grey.100" />}
              aria-label="Open Section"
              onClick={() => setIgnitionSheetSectionOpen(true)}
            />
          )}
          {ignitionSheetSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<UpIcon color="grey.100" />}
              aria-label="Close Section"
              onClick={() => setIgnitionSheetSectionOpen(false)}
            />
          )}
        </Flex>
        {ignitionSheetSectionOpen && <Table data={ignitionSheet} onRowClicked={handleIgnitionSheetRowClick} columns={IgnitionSheetColumns} />}
      </Box> */}
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Flex w="100%">
          <Heading size="md" mt={2} mb={6} mr={3}>
            GRIP Optional Kit Manuals
          </Heading>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={handleKitInstructionsCreate}>
              Create
            </Button>
          )}
          <Spacer />
          {!kitInstallSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<DownIcon color="grey.100" />}
              aria-label="Open Section"
              onClick={() => setKitInstallSectionOpen(true)}
            />
          )}
          {kitInstallSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<UpIcon color="grey.100" />}
              aria-label="Close Section"
              onClick={() => setKitInstallSectionOpen(false)}
            />
          )}
        </Flex>
        {kitInstallSectionOpen && <Table data={kitInstructions} onRowClicked={handleKitInstructionsRowClick} columns={KitInstructionsColumns} />}
      </Box>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Flex w="100%">
          <Heading size="md" mt={2} mb={6} mr={3}>
            GRIP Kit Manuals
          </Heading>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={handleManualsCreate}>
              Create
            </Button>
          )}
          <Spacer />
          {!manualsSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<DownIcon color="grey.100" />}
              aria-label="Open Section"
              onClick={() => setManualsSectionOpen(true)}
            />
          )}
          {manualsSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<UpIcon color="grey.100" />}
              aria-label="Close Section"
              onClick={() => setManualsSectionOpen(false)}
            />
          )}
        </Flex>
        {manualsSectionOpen && <Table data={manuals} onRowClicked={handleManualsRowClick} columns={ManualsColumns} />}
      </Box>

      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Flex w="100%">
          <Heading size="md" mt={2} mb={6} mr={3}>
            GRIP Service Bulletins
          </Heading>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={handleServiceBulletinsCreate}>
              Create
            </Button>
          )}
          <Spacer />
          {!bulletinsSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<DownIcon color="grey.100" />}
              aria-label="Open Section"
              onClick={() => setBulletinsSectionOpen(true)}
            />
          )}
          {bulletinsSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<UpIcon color="grey.100" />}
              aria-label="Close Section"
              onClick={() => setBulletinsSectionOpen(false)}
            />
          )}
        </Flex>
        {bulletinsSectionOpen && <Table data={serviceBulletin} onRowClicked={handleServiceBulletinRowClick} columns={ServiceBulletinsColumns} />}
      </Box>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Flex w="100%">
          <Heading size="md" mt={2} mb={6} mr={3}>
            GRIP Windows Applications
          </Heading>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={handleSoftwareCreate}>
              Create
            </Button>
          )}
          <Spacer />
          {!softwareSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<DownIcon color="grey.100" />}
              aria-label="Open Section"
              onClick={() => setSoftwareSectionOpen(true)}
            />
          )}
          {softwareSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<UpIcon color="grey.100" />}
              aria-label="Close Section"
              onClick={() => setSoftwareSectionOpen(false)}
            />
          )}
        </Flex>
        {softwareSectionOpen && <Table data={software} onRowClicked={handleSoftwareRowClick} columns={SoftwareColumns} />}
      </Box>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Flex w="100%">
          <Heading size="md" mt={2} mb={6} mr={3}>
            Grip Application Software
          </Heading>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={handleGripControllerApplicationCreate}>
              Create
            </Button>
          )}
          <Spacer />
          {!gripControllerSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<DownIcon color="grey.100" />}
              aria-label="Open Section"
              onClick={() => setGripControllerSectionOpen(true)}
            />
          )}
          {gripControllerSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<UpIcon color="grey.100" />}
              aria-label="Close Section"
              onClick={() => setGripControllerSectionOpen(false)}
            />
          )}
        </Flex>
        {gripControllerSectionOpen && (
          <Table data={gripControllerApplication} onRowClicked={handleGripControllerApplicationRowClick} columns={GripControllerApplicationColumns} />
        )}
      </Box>
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        mb={5}
        backgroundColor="background"
        style={{ boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <Flex w="100%">
          <Heading size="md" mt={2} mb={6} mr={3}>
            Training Documents
          </Heading>
          {isAllowedToEdit() && (
            <Button colorScheme="gripgreen" bg="gripgreen" onClick={handleTrainingCreate}>
              Create
            </Button>
          )}
          <Spacer />
          {!trainingDocumentSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<DownIcon color="grey.100" />}
              aria-label="Open Section"
              onClick={() => setTrainingDocumentSectionOpen(true)}
            />
          )}
          {trainingDocumentSectionOpen && (
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              icon={<UpIcon color="grey.100" />}
              aria-label="Close Section"
              onClick={() => setTrainingDocumentSectionOpen(false)}
            />
          )}
        </Flex>
        {trainingDocumentSectionOpen && <Table data={trainingDocument} onRowClicked={handleTrainingDocumentsRowClick} columns={TrainingColumns} />}
      </Box>
    </>
  );
};

export default ResourcePage;
