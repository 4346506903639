import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

import KitForm from "../../forms/KitForm";

const KitEditPage: React.FC = () => {
  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/kit-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Kit Manager
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Add Kit</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <KitForm />
    </>
  );
};

export default KitEditPage;
