import { Breadcrumb, BreadcrumbItem, Text, BreadcrumbLink } from "@chakra-ui/react";
import React from "react";
import PartCategoryForm from "../../../forms/PartCategoryForm";
import { NavLink } from "react-router-dom";

const CategoryFormCreatePage: React.FC = () => {
  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Parts Manager</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/part-manager/product-codes" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Product Codes
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Add Product Code</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <PartCategoryForm />
    </>
  );
};

export default CategoryFormCreatePage;
