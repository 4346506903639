import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  IconButton,
} from "@chakra-ui/react";
import React from "react";

type DeleteConfirmationDialogProps = {
  handleDelete: () => void;
  title?: string | undefined;
  variant?: string | "solid";
  icon?: React.ReactElement | undefined;
  isSoftDelete?: boolean | undefined; // If set to false (default), The user will see "Once confirmed, this action cannot be undone"
};

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({ handleDelete, title, variant, icon, isSoftDelete }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef(null);

  return (
    <>
      {icon ? (
        <IconButton variant="ghost" colorScheme="red" size="sm" icon={icon} aria-label="Delete" onClick={() => setIsOpen(true)} />
      ) : (
        <Button colorScheme="red" variant={variant} onClick={() => setIsOpen(true)}>
          {title ?? "Delete"}
        </Button>
      )}

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent mt="80px">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title ?? "Delete"}
            </AlertDialogHeader>

            <AlertDialogBody>
              Please confirm that you wish to {title ?? "Delete"} this item. {!isSoftDelete && <>Once confirmed, this action cannot be undone.</>}
              <br />
              <br />
              <strong>Please be certain.</strong>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleDelete();
                  onClose();
                }}
                ml={3}
              >
                {title ?? "Delete"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteConfirmationDialog;
