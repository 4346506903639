import * as React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";

import { Field, FieldProps } from "formik";

export interface SelectFieldOptionProps {
  label: string;
  value: number | string;
}

type SelectFieldProps = {
  label: string;
  name: string;
  options: SelectFieldOptionProps[];
  error?: string | undefined;
  isRequired?: boolean;
  titleAddonElement?: React.ReactElement | undefined;
};

const SelectField2: React.FC<SelectFieldProps> = ({
  label,
  name,
  options,
  isRequired = true,
  titleAddonElement,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl
          id={name}
          isRequired={isRequired}
          isInvalid={(meta.error && meta.touched) || false}
        >
          <Box
            flexDirection="row"
            display="flex"
            justifyContent="space-between"
          >
            <FormLabel>{label}</FormLabel>
            {titleAddonElement}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            m="auto"
            alignItems="center"
          >
            <Select placeholder={"Select " + label} {...field}>
              {options.map((v: SelectFieldOptionProps) => (
                <option key={v.value} value={v.value}>
                  {v.label}
                </option>
              ))}
            </Select>
          </Box>
          {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};

export default SelectField2;
