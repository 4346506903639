import { Breadcrumb, BreadcrumbItem, Text, BreadcrumbLink, useToast } from "@chakra-ui/react";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import PartCategoryForm from "../../../forms/PartCategoryForm";
import { GetPartCategoryDto } from "../../../helpers/GripApi";
import useAuth from "../../../hooks/useAuth";

const CategoryFormEditPage: React.FC = () => {
  const params: any = useParams();
  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  const partCategoryId = params.partCategoryId;

  const [partCategory, setPartCategory] = React.useState<GetPartCategoryDto>();

  React.useEffect(() => {
    if (partCategoryId) {
      auth.client
        ?.partCategory_Get(partCategoryId, "1.0")
        .then((r) => {
          setPartCategory(r.result);
        })
        .catch((e) => {
          toast({
            position: "bottom",
            status: "error",
            title: "There was an error getting this part category.",
          });
          history.goBack();
        });
    } else {
      history.goBack();
    }
  }, [auth.client, history, partCategoryId, toast]);
  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Parts Manager</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/part-manager/product-codes" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Product Codes
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Edit Product Code</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      {partCategory && <PartCategoryForm partCategory={partCategory} />}
    </>
  );
};

export default CategoryFormEditPage;
