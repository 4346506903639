import React from "react";
import { useParams } from "react-router";
import useAuth from "../../hooks/useAuth";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import ClientForm from "../../forms/ClientForm";
import { GetClientDto } from "../../helpers/GripApi";

const ClientEditPage: React.FC = () => {
  const params: any = useParams();
  const auth = useAuth();
  const [client, setClient] = React.useState<GetClientDto>();

  const userId = params.userId;

  React.useEffect(() => {
    if (userId) {
      auth.client?.client_Get(userId, "1.0").then((r) => {
        setClient(r.result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={10}>
        <BreadcrumbItem>
          <Text>Client Manager</Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/client-manager" activeClassName="activeBreadcrumbLink" textColor="gripgreen" exact>
            Clients
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text>Edit Client</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <ClientForm client={client} profile={false} />
    </>
  );
};

export default ClientEditPage;
