/* eslint-disable react/jsx-props-no-spreading */
import { Image, Link, Stack } from "@chakra-ui/react";
import * as React from "react";
import { SRLWrapper } from "simple-react-lightbox";

type MediaPreviewProps = {
  src?: string | undefined;
  objectURL?: any | undefined;
  filename?: string | undefined;
  maxWidth?: string | number | "none";
};

const options = {
  settings: {
    overlayColor: "#F0F0F0",
  },
};

const MediaPreview: React.FC<MediaPreviewProps> = ({ src, objectURL, filename, maxWidth }) => {
  let fallbackURL = "/assets/images/file-unknown.jpg";
  let type = undefined;

  const imageExtensions = [".gif", ".pjp", ".jpg", ".pjpeg", ".jpeg", ".jfif", ".png", ".svgz", ".svg", ".webp"];
  const videoExtensions = [".webm", ".mp4", ".m4v"];
  const fileExtensions = [".pdf"];
  const softwareExtensions = [".exe"];
  const zipExtensions = [".zip"];

  if (src) {
    if (imageExtensions.some(src.includes.bind(src))) {
      type = "image";
      fallbackURL = "/assets/images/file-image.jpg";
    } else if (videoExtensions.some(src.includes.bind(src))) {
      type = "video";
      fallbackURL = "/assets/images/file-video.jpg";
    } else if (fileExtensions.some(src.includes.bind(src))) {
      type = "pdf";
      fallbackURL = "/assets/images/file-text.jpg";
    } else if (softwareExtensions.some(src.includes.bind(src))) {
      type = "exe";
      fallbackURL = "/assets/images/file-text.jpg";
    } else if (zipExtensions.some(src.includes.bind(src))) {
      type = "zip";
      fallbackURL = "/assets/images/file-unknown.jpg";
    }

    switch (type) {
      case "image":
        return (
          <SRLWrapper options={options}>
            <Image src={src} fallbackSrc={fallbackURL} maxWidth={maxWidth} />
          </SRLWrapper>
        );
      case "pdf":
      case "exe":
        return (
          <Stack width={maxWidth}>
            <Image src={src} fallbackSrc={fallbackURL} maxWidth={maxWidth} />
            <Link href={src} isExternal textAlign="center">
              Download
            </Link>
          </Stack>
        );
      case "video":
        return (
          <video width={maxWidth} controls>
            <source src={src} type="video/mp4"></source>
            <Image src={fallbackURL} />
            Your browser does not support HTML5 video.
          </video>
        );
      case "zip":
        return (
          <Stack width={maxWidth}>
            <Image src={src} fallbackSrc={fallbackURL} maxWidth={maxWidth} />
            <Link href={src} isExternal textAlign="center">
              Download
            </Link>
          </Stack>
        );
      default:
        return <Image src={src} fallbackSrc={fallbackURL} maxWidth={maxWidth} />;
    }
  } else if (objectURL) {
    if (filename) {
      if (imageExtensions.some(filename.includes.bind(filename))) {
        fallbackURL = "/assets/images/file-image.jpg";
      } else if (videoExtensions.some(filename.includes.bind(filename))) {
        fallbackURL = "/assets/images/file-video.jpg";
      } else if (fileExtensions.some(filename.includes.bind(filename))) {
        fallbackURL = "/assets/images/file-text.jpg";
      } else if (zipExtensions.some(filename.includes.bind(filename))) {
        type = "zip";
        fallbackURL = "/assets/images/file-unknown.jpg";
      }
    }
    return <Image src={objectURL} fallbackSrc={fallbackURL} maxWidth={maxWidth} />;
  } else {
    return <Image src={fallbackURL} maxWidth={maxWidth} />;
  }
};

export default MediaPreview;
